@import "./global-propierties";

.dash{
  &__dim {
    margin: 2rem 6rem 6rem 6rem;
    border: 1px solid transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 2px 12px rgba(0, 0, 0, .06);
  }

  &__title {
    margin: 10px 10px 10px 10px;
  }

  &__text{
    margin: 20px 1px 1px 30px;
    color: $main-survey-blue;
  }

  &__not-found{
    margin-bottom: 0;
    font-size: 20px;
    color: $color-alert;
    padding-right: 25px;
    text-align: center;
  }

  &__nbResponse{
    font-size: 15px;
    color: $main-survey-blue;
    margin: 20px 1px 1px 30px;
  }
}
