#ttp-tabs {
  .wrapper {
    text-align: center;
    margin: 0px auto;

    .tabs {
      font-size: 15px;
      padding: 0px;
      list-style: none;
      background: #fff;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      display: inline-block;
      border-radius: 50px;
      position: relative;

      a {
        margin-right: 5px;
        text-decoration: none;
        color: #777;
        text-transform: uppercase;
        padding: 10px 20px;
        display: inline-block;
        position: relative;
        z-index: 1;
        transition-duration: 0.6s;
        width: 260px;
      }

      a.active {
        color: #fff;
      }

      .selector {
        // width: 50%;
        width: 100%; //TO REMOVE
        left: 0;
        height: 100%;
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        border-radius: 50px;
        transition-duration: 0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

        background: #05abe0;
        background: -moz-linear-gradient(104deg, #57d0f2, #5081be);
        background: -webkit-linear-gradient(104deg, #57d0f2, #5081be);
        background: linear-gradient(104deg, #57d0f2, #5081be);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#57d0f2', endColorstr='#5081be', GradientType=1);
      }
    }
  }
}
