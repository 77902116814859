@import "../global-propierties";

.toolbar-list-item {
  width: 100%;
  .block-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: $toolbarItem-margin;
    background-color: #2b2e33;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    text-transform: uppercase !important;

    &.light {
      background-color: #fff;
      &:hover {
        background-color: #F0F0F0;
        border-left: none;
        font-weight: 700;
      }
    }
    &:hover {
      background-color: #222528;
      border-left: 3px solid #5081be;
      font-weight: 700;
      color: #ddd;
    }
    &--no-spacing {
      margin: 0;
      &.selected {
        background-color: #222528;
        border-left: 3px solid #5081be;
        font-weight: 700;
        color: #ddd; 
      }
    }
    .block-icon {
      display: inline-block;
      margin: 4px 12px 4px 4px;
      border-radius: 2px;
      color: white;
      ._icon {
        width: $icon-big-size;
        height: $icon-big-size;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .block-title {
      flex-grow: 1;
      color: #8d8d8d;
      font-family: $main-body-font;
      font-weight: normal;
    }

    &.disabled-link {
      cursor: not-allowed;
    }
  }

  &.main {
    .block-icon, .block-title {
      color: $french-pass;
    }

    .block-icon {
      margin-left: $spacing-xs;
    }

    .block-wrapper {
      background: transparent;
      border-color: $dodger-blue;
      box-shadow: none;

      &:hover, &.selected {
        background-color: $fiord;
      }
    }
  }
}

.disabled-link > .block-wrapper{
  cursor: not-allowed;
}