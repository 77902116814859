.piechart{
  background-size     : cover;
  background-position : 50% 50%;
  margin              : 0 0 10px;
  transition          : all .3s ease-in-out !important;
  &-wrapper{
    position: relative;
  }
  &-container{
    position: relative;
    display: table;
    margin: 0 auto;

    .piechart__percentage, .piechart__search{
      text-align: center;
      position: absolute;
      line-height: 200px;
      width: 100%;
      z-index: 1;
      color: $gray-dark !important;
      border-radius: 50%;
      cursor: pointer;
      width: 200px;
      height: 200px;
      span{
        text-align: center;
      }
      &-symbol{
        position: absolute;
        bottom: 5%;
        font-size: 20px;
      }
      &-number{
        font-size: 36px;
      }
    }

    .piechart__search{
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-magnifier{
        font-size: 3.5rem;
        font-weight: bold;
        color: #FFF;
      }
    }
}

  svg{
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  &-heading{
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
    padding: 1rem;
  }
  &-footer {
    font-weight: 300;
  }

  &-circle__empty{
    stroke-width: 20;
    box-sizing: border-box;
    color: $main-color;
  }
  &-circle__fill{
    stroke-width: 20;
    transition: all 3s ease-in-out;
  }

&-info{
    cursor: pointer;
    font-size: 1rem;
    i {
      font-weight: 700;
    }
  }
}
