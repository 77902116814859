$page-size: 36px;
.pagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    line-height: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1.5rem auto;
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 .1rem;
        padding: 0;
        &:last-child, &:first-child {
            display: flex;
        }
    }

    a:hover {
        background: transparent;
    }
    .next, .previous {
        text-transform: uppercase;
        background: #F8F9FA;
        border-radius: 40px;
        width: 130px;
        height: $page-size;
        a {
            color: #18A0FB;
        }
    }
    .disabled a {
        color: rgba(109, 127, 146, 0.5);
    }
    .page, .break {
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 14px;
        color: #3C4E64;
        width: $page-size;
        height: $page-size;
        a {
            color: #3C4E64;
        }
        &:hover {
            background: #F1F1F1;
        }
    }
    .current, .current:hover {
        background: #2495E1;
        a {
            color: #fff;
        }
    }
    .disabled {
        padding: 0;
        a {
          cursor: not-allowed !important;
        }
    }
    .pagination li:last-child, .pagination li:first-child {
        display: flex;
    }
}
