.editor-block {

  &__container {
    &:hover {
      .editor-block__actions {
        opacity: 1;
        z-index: 7;
      }
    }
  }

  &__actions {
    &.block {
      .add-block__button {
        background: #3f3f3f;
        color: #fff;
        height: 34px;
        width: 34px;
        font-size: 12px;
      }
      .add-block__wrapper {
        left: 45px;
      }
    }
  }
}

.add-block {
  position: relative;

  &:hover {
    .add-block__button {
      transform: scale(1.1);
      color: #fff;
      background: #3f3f3f;
    }
  }

  &__tabs {
    background: #f0f0f0;
    background: #f1f2f4;
    border-radius: 8px 8px 0 0;
    display: flex;
    color: #3c4e64;
    cursor: pointer;

    & > div {
      padding: 10px;
      flex: 1;
      text-align: center;

      &.active {
        background: #d7dbe0;
        background: #d7dbe0;
      }
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  &__button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease 0s;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.25s;
    z-index: 4;
    // padding: 7px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #3f3f3f;
    bottom: -16px;
    cursor: pointer;
  }

  &__wrapper {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
    background: #fff;
    left: 35px;
    margin-top: -25px;
    position: absolute;
    width: 300px;
    border-radius: 8px;

    position: absolute;
    z-index: 10;

    max-height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    & > div {
      flex-shrink: 0;
      flex: 0;
    }
  }

  &__header {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    color: #3c4e64;
  }

  &__content {
    padding: 0 1rem;
  }

  &__blocks {
    overflow-y: auto;
    flex-shrink: 1;
    flex: 1;
  }

  &__block_item {
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .block-icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.4rem;
      border-radius: 4px;

      span {
        color: #fff;
      }
    }

    .block-title {
      font-size: 14px;
      color: #3C4E64;
    }

    &:hover {
      background: #f1f2f4;
    }
  }
}
