#mainMenu {
  z-index: 5;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  &, ul, .top-bar {
    background-color: #fff;
  }
  .title-bar {
    background-color: #fff;
  }
  .top-bar {
    padding: 0;
    width: 100%;
    align-items: stretch;
  }
  .menu__link-create {
    font-weight: bold;
    color: $main-blue;
  }
  .boxes__box__logo {
    height: 5rem;
    width: 90%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    margin-bottom: 20px;
  }
  nav {
    li {
      margin: 0 .5rem;
      transition: nth($transitions, 3);
      position: relative;
      // top: 1px;
      cursor: pointer;
      .menu__link {
        padding: 1.5rem 1rem;
        color: $fiord;
        font-family: $main-body-font;
        &.active {
          border-bottom: 2px solid;
          color: #2c2b3f;
        }
      }
      &:hover .menu__link {
        color: $curious-blue;
      }
    }
  }
  .empty-avatar span {
    font-size: 1rem;
  }
  .menu__logo {
    padding: 0 1.3rem;
    position: relative;
    align-items: center;
    display: flex;
    z-index: 1;

    img {
      height: 2.8rem;
    }

    .name {
      color: $pickled-bluewood;
      font-size: 18px;
      font-family: $main-body-font;
    }

    &.shadow {
      transition: 0.2s all ease-in-out;
      box-shadow: 1px 0 4px rgba(41, 57, 77, 0.1);
    }

    .icon-options {
      transform: rotate(90deg);
      margin-right: $spacing-s;
      cursor: pointer;
    }

    .menu__dropdown {
      text-align: center;
      font-family: $main-body-font;
      font-weight: 500;
      font-size: 12px;
      color: $pickled-bluewood;
      padding-left: 0;
      padding-right: 0;
      left: 0;
      width: 100%;

      li {
        padding: $spacing-s 0;

        &:hover {
          background: $athens-gray-lighter;

          a {
            color: $dodger-blue;
          }
        }
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }

      &::before {
        content: "";
        display: block;
        left: .751rem;
        right: .751rem;
        height: 1px;
        position: absolute;
        background: $athens-gray-lighter;
      }
    }
  }
  .menu__icone {
    padding: 1rem;
    font-size: 1.5rem;
    color: $main-blue;
    cursor: pointer;
  }
  a {
    color: $pickled-bluewood;
    white-space: normal;
    padding: 0;
  }
  .menu__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 0px 0px 4px 4px;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: nth($transitions, 2);
    padding: 0 2rem 1rem;
    background: #fff;
    z-index: 5;
    width: 24rem;
    .menu__dropdown-top {
      padding: 1rem 0;
      border-bottom: 1px solid rgba(44, 43, 63, 0.1);
      display: flex;
      justify-content: space-between;
      span {
        margin: 0;
        color: #2c2b3f;
      }
      .seeAll {
        transition: nth($transitions, 2);
        color: #95959f;
        font-size: .7rem;
        cursor: pointer;
        &:hover {
          color: #2c2b3f;
        }
      }
    }
  }
  .menu__img, .menu__has-child {
    .menu__link {
      line-height: 1;
      display: flex;
      align-items: center;
    }
    .menu__dropdown {
      padding-top: 1rem;
      width: 15rem;
      li {
        margin: 1rem 0;
        transition: nth($transitions, 2);
        &:hover > a {
          color: #2c2b3f;
        }
      }
    }
  }
  .profile {
    .menu__dropdown {
      width: 20rem;
      padding: 0;
      background: $athens-gray;
      top: calc(100% + .6rem);
      right: -5px;

      & > li {
        margin: $spacing-s $spacing-xxs;
      }

      .menu__img-wrap {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: $spacing-xs;
        margin-left: $spacing-xs;
      }

      .infos {
        text-transform: none;
        color: $pickled-bluewood;

        .email {
          font-size: .75rem;
          color: $slate-gray;
          text-transform: capitalize;
        }
      }

      .social {
        margin-top: 0 !important;
        background: #fff;
        position: relative;
        cursor: default;

        ul {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            font-size: 22px;
            color: $slate-gray;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: $spacing-xs;
            }
          }

          i {
            padding: $spacing-xs;
            &:hover {
              background: $black-haze;
              border-radius: 50%;
              line-height: 1;
            }
          }
            
        }

        &::before {
          content: "";
          display: block;
          height: 1px;
          width: 90%;
          position: absolute;
          background: $athens-gray-lighter;
          left: 5%;
        }
      }

      .logout {
        padding: $spacing-xxs;

        a {
          color: $dodger-blue;
        }

        &:hover {
          a {
            color: $curious-blue;
          }
        }
      }
    }
  }

  .menu__has-child {
    &.menu__community {
      background: $athens-gray-lighter;
      width: 15rem;
      font-size: 16px;
      font-weight: normal;
      margin-left: 0;

      .menu__link {
        width: 100%;
        justify-content: space-between;
      }
      a{
        display: inline-block;
        padding: 0;
      }
      .menu__sub-child {
        padding: 1rem;
        margin: 0;

        &::after {
          content: "";
          display: block;
          border-bottom: 1px solid $athens-gray-lighter;
          bottom: 0;
          left: 0.5rem;
          right: 0.5rem;
          position: absolute;
        }
        &:hover {
          background: $athens-gray-lighter;

          a {
            color: $dodger-blue;
          }
        }
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &:last-child {
          margin-bottom: 0;
          border: none;
        }
      }
      .menu__dropdown {
        width: 15rem;
        padding: 0;
      }

      &:hover{
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
        background: #FFF;
      }
    }
    &.menu__author .empty-avatar span {
      color: #fff;
      margin: 0;
    }
    .icon {
      position: relative;
      top: 1px;
      left: 1rem;
    }
    .menu__dropdown {
      width: 24rem;
      /*top: 2.92rem;*/
      left: 0;
      border-top-left-radius: 0;
      padding-bottom: 0;

      .menu__dropdown-top {
        border-bottom: none;
        border-top: 1px solid rgba(44, 43, 63, 0.1);
      }

    }

    .menu__sub-child {
      &.hidedItem {
        display: none;
        transition: none;
      }
      display: flex;

      .first-letter {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    &:hover .menu__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
  .menu__community, .menu__create-campaign {
    .menu__dropdown ul {
      text-transform: none;
      margin: 0;
      a {
        padding: 0;
        width: 100%;
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        font-family: $main-body-font;
        color: $pickled-bluewood;
        letter-spacing: .06rem;
      }
    }
  }
  .menu__create-campaign {
    .icon-note{
      left: 0;
    }
    .menu__dropdown {
      width: 15em;
    }
  }
  .menu__create-survey {
    .menu__link{
      display: flex;
    }
    .icon-note {
      left: 0;
      padding-right: 5px;
    }
  }
  .menu__img-wrap {
    width: 2.8rem;
    border-radius: 3rem;
    overflow: hidden;
    height: 2.8rem;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }

  .top-bar-right {
    display: flex;
    &.menu__off .menu__language {
      display: flex;
      ul {
        height: auto;
      }
    }
    .menu__language {
      margin: 0;
      padding: $spacing-m $spacing-s;
      background: #fff;
      margin-bottom: 0 !important;
      cursor: default;

      ul {
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: bold;
        height: 2rem;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          margin: 0 $spacing-xs;
          border: 1px solid $french-pass;
          color: $dodger-blue;
          font-family: $main-body-font;
          font-weight: normal;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.2s font-size ease-in-out;

          &:hover, &.selected {
            background-color: $dodger-blue;
            color: #FFF;
          }
        }
      }
    }
    .menu__profile {
      margin: 0;
      padding: $spacing-m $spacing-s;
      background: #fff;
      &:before {
        content: "";
        display: block;
        left: 0;
        right: 0;
        height: 1px;
        position: absolute;
        background: $athens-gray-lighter;
        top: 4rem;
      }
      ul {
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: bold;
        height: .5rem;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          cursor: pointer;
          transition: 0.2s font-size ease-in-out;
          padding: 1.7rem 9.7rem;
          &:hover {
            background: $athens-gray-lighter;
              a {
                color: $tag-color;
              }
            }
          a {
            font-size: 12px;
          }
        }
      }
    }
    .menu {
      margin: 0 1.3rem;
      align-items: center;

      .menu__img {
        padding: 0.5rem;

      }
      .e-box-li {
        width: 2.8rem;
        overflow: hidden;
        height: 2.8rem;
        border-radius: 2rem !important;
        padding: 0 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        &:hover {
          &:before {
            position: relative !important;
          }
        }
        &.active-e-box {
          background-color: #e1e1e4;
        }
        .badge-new {
          margin-bottom: 0.75rem;
          margin-left: -3.7rem;
          border-bottom-right-radius: 0;
        }
        img {
          margin: 0;
          width: 1.6rem;
        }
      }
      & > li {
        padding: 0.8rem 1rem;
        position: relative;
        border-radius: 2rem 2rem 0 0;
        transition: nth($transitions, 2);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 2.8rem;
        height: 2.8rem;

        .icon {
          margin-right: .4rem;
        }
        & > span {
          font-size: 1.3rem;
          &.dotted:before {
            top: 21%;
            right: 27%;
          }
        }

        &:hover {
          background-color: $athens-gray;

          .badge {
            visibility: hidden;
          }

          &.expandable:after {
            content: ' ';
            position: absolute;
            bottom: 0px;
            z-index: 6;

            bottom: -.6rem;
            left: calc(50% - 7px);
            z-index: 6;
            background: transparent;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid $athens-gray;
          }
          .menu__dropdown, .social-links__wrapper {
            opacity: 1;
            visibility: visible;
          }
        }
        .badge {
          position: absolute;
          bottom: 0;
          right: 0;
          background: $coral-red;
          font-family: $main-body-font;

          display: inline-block;
          min-width: 1.3rem;
          padding: 0.2rem;
          border-radius: 50%;
          font-size: 0.7rem;
          text-align: center;
          color: #fefefe;
          font-weight: 700;
          transition: visibility .2s ease-in-out;
          line-height: 1.4;
        }
      }
    }
    .sign-in {
      padding: 1.64rem 2rem;
      display: inline-block;
      font-weight: 600;
      transition: nth($transitions, 2);
      &:hover {
        color: $main-blue;
      }
    }

    .buttons {
      display: flex;
      margin: 0;
      align-items: center;
      font-size: 1.5rem;
      margin-right: 3px;
      padding: .6rem 0;

      .notif {
        .menu__dropdown, .social-links__wrapper {
          right: calc(-75px - 49px);
        }
      }
  
      .ebox {
        .menu__dropdown, .social-links__wrapper {
          right: calc(-75px - 49px*2);
        }
      }
  
      .apps {
        .menu__dropdown, .social-links__wrapper {
          right: -75px;
        }

        .social-links__body > ul {
          display: flex;
          flex-wrap: wrap;
          padding: $spacing-s;

          li {
            margin-bottom: $spacing-s;
          }

          .app-wrapper {
            padding: $spacing-xs;
            border: 0.5px solid #EEF6FF;
            box-sizing: border-box;
            box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.05);
            border-radius: 4px;
            text-align: center;
            text-transform: none;
            cursor: pointer;

             img {
              width: 2.5rem;
              padding-bottom: $spacing-xs;
            }
            &.enabled {
              box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.05);
              border: 1px solid #eef6ff;
              &:hover {
                background-color: #b3d4fc;
                span,
                .app-name {
                  color: #18a0fb;
                }
              }
            }
            &.disabled {
              opacity: 0.35;
              cursor: inherit;
            }
            .app-name {
              font-family: $main-header-font;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: $fiord;
            }

            &:hover {
              background: $alice-pass;
              box-shadow: none;
              border: none;

              .app-name{
                color: #2495E1;
                font-weight: 600
              }
            }
          }
        }
      }
      
      a{
        padding: 0;
      }
  
      .social-links {
        &__header {
          background: $athens-gray;
          font-size: 14px;
          text-align: center;
          color: $curious-blue;
          padding: 1.5rem 1rem;
          line-height: 0.2rem;
          font-family: $main-body-font;
          text-transform: none;
          font-weight: normal;
        }

        &__body {
          background: $athens-gray;

          & > ul {
            margin: 0 $spacing-xxs $spacing-xxs;
            border-top: 2px solid #fff;
          }
        }

        &__wrapper {
          top: calc(100% + .6rem);
          padding: 0;
          width: 20rem;
          background: $athens-gray;
          position: absolute;
          box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
          border-radius: 0px 0px 4px 4px;
          margin: 0;
          opacity: 0;
          visibility: hidden;
          transition: 0.2s all ease-in-out;
          z-index: 5;
          cursor: default;
        }
      }

      & > .social-links {
        padding: 0 0.6rem;
        margin: 0 .12rem;
        position: relative;

        .active::after{
          content: " ";
          width: 8px;
          height: 8px;
          position: absolute;
          border: 1px solid #fff;
          background-color: red;
          bottom: 8px;
          right: 12px;
          border-radius: 50%;
        }

        .sub-menu__dropdown {
  
          span {
            font-size: 0.75rem;
            text-transform: none;
          }
  
          & > li {
            line-height: 0.2rem;
            padding: $spacing-m $spacing-s $spacing-xxs;
            font-size: 12px;
            color: $pickled-bluewood;
            font-family: $main-body-font;
            text-transform: none;
            font-weight: normal;
            background: #fff;
  
            &:hover {
              background: $athens-gray-lighter;
              a {
                color: $dodger-blue;
              }
            }
            &:not(:last-child){
              border-bottom: 1px solid $athens-gray-lighter;
            }
            a {
              font-size: 0.75rem;
              line-height: 1.1rem;
              text-transform: none;
              color: $pickled-bluewood;
            }
            &:last-child {
              border-bottom: none;
            }
  
  
            &.notRead {
              background: $athens-gray;
            }

            .infos {
              padding-top: $spacing-xs;
              text-align: right;
              font-family: $main-body-font;
              font-style: normal;
              font-weight: 300;
              font-size: 10px;
              color: #6D7F92;
            }
          }
        }
        .e-box-icon {
          width: 2rem !important;
          padding-left: .2rem;
          padding-bottom: .3rem;
          height: 2rem;
        }

        & > a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
  
      li {
        padding: 0 .6rem;
        transition: nth($transitions, 3);
        &:hover::after {
          background-color: inherit;
        }
        &:hover a {
          color: $fiord;
          border: 0;
        }
      }
    }
  }

  .menu__notifs-contents, .menu__messages-contents {
    margin: 0;

    li {
      margin: 1.5rem 0;
      & > a {
        display: flex;
        text-transform: initial;
        transition: nth($transitions, 2);
        position: relative;
        &:hover {
          color: #2c2b3f;
        }

      }
      span {
        margin-right: 1rem;
        font-size: 1.3rem;
        color: $main-blue;
      }
      p {
        margin: 0.2rem 0 0 0;
        strong {
          margin: 0 .3rem;
        }
      }
    }
    .menu__notifs-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 86%;
      span {
        font-size: 0.8rem;
        color: #bbb;
        font-family: "Montserrat";
      }
    }
  }
  .menu__messages-contents {
    .menu__img-wrap {
      margin-right: 1rem;
    }
    h4 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 0.8rem;
      color: #999;
    }
  }

  .menu__messages > span.dotted:before {
    top: 24%;
    right: 19%;
  }

  .badge-new {
    font-size: 0.5rem;
    background-color: orange;
    color: white;
    font-weight: 700;
    padding: 0.4rem;
    padding-top: 0.1rem;
    padding-bottom: 0;
    position: absolute;
    border-radius: .8rem;
    letter-spacing: .06rem;
  }
}
.offCanvas-wrapper--open {
  .off-canvas-content {
    transform: translate(250px);
  }
}


@include mui-series {
  #mac-area{ @include mui-queue(2s, 0s, slide($direction: top)); animation-fill-mode: backwards;}
  #iphone-area{ @include mui-queue(1s, 0s, slide($direction: left)); animation-fill-mode: backwards;}
}

.dotted:before {
  content: '';
  border-radius: 2rem;
  background-color: $main-orange;
  top: 22%;
  right: 0;
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border: 0.15rem solid #fff;
}
