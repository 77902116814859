@import "../global-propierties";

.display-errors{

  &:not(:first) {
    margin-top: 1rem;
  }
  
  &__message {
    border-radius: 3px;
    width: 100%;
    padding: 7px 10px;
    background-color: #FF5656;
    color: #fff;
    text-align: center;
    display: block;
  }
}
