@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?e38yzf');
  src:  url('/fonts/icomoon.eot?e38yzf#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?e38yzf') format('truetype'),
    url('/fonts/icomoon.woff?e38yzf') format('woff'),
    url('/fonts/icomoon.svg?e38yzf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon"], [class*=" icomoon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exit-full-screen:before {
  content: "\e94c";
}
.icon-full-screen:before {
  content: "\e94d";
}
.icon-tt-correct:before {
  content: "\e926";
}
.icon-tt-template:before {
  content: "\e948";
}
.icon-tt-question:before {
  content: "\e949";
}
.icon-tt-colors:before {
  content: "\e94a";
}
.icon-tt-settings:before {
  content: "\e94b";
}
.icon-tt-menu:before {
  content: "\e941";
}
.icon-tt-event:before {
  content: "\e942";
}
.icon-tt-stats:before {
  content: "\e943";
}
.icon-tt-folder:before {
  content: "\e944";
}
.icon-tt-network:before {
  content: "\e946";
}
.icon-tt-envelope-open:before {
  content: "\e947";
}
.icon-tt-profil:before {
  content: "\e93d";
}
.icon-tt-closed-envelope:before {
  content: "\e93e";
}
.icon-tt-grid:before {
  content: "\e93f";
}
.icon-tt-bell:before {
  content: "\e940";
}
.icon-tt-contact:before {
  content: "\e939";
}
.icon-tt-instagram:before {
  content: "\e93a";
}
.icon-tt-facebook:before {
  content: "\e93b";
}
.icon-tt-linkedin:before {
  content: "\e93c";
}
.icon-tt-unlocked .path1:before {
  content: "\e92a";
  color: rgb(199, 202, 199);
}
.icon-tt-unlocked .path2:before {
  content: "\e92b";
  margin-left: -1.357421875em;
  color: rgb(231, 236, 237);
}
.icon-tt-unlocked .path3:before {
  content: "\e92f";
  margin-left: -1.357421875em;
  color: rgb(41, 57, 77);
}
.icon-tt-unlocked .path4:before {
  content: "\e930";
  margin-left: -1.357421875em;
  color: rgb(41, 57, 77);
}
.icon-tt-locked .path1:before {
  content: "\e931";
  color: rgb(199, 202, 199);
}
.icon-tt-locked .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(231, 236, 237);
}
.icon-tt-locked .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(41, 57, 77);
}
.icon-tt-locked .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(41, 57, 77);
}
.icon-tt-pin:before {
  content: "\e938";
}
.icon-tt-copy:before {
  content: "\e927";
}
.icon-tt-edit:before {
  content: "\e928";
}
.icon-tt-eye:before {
  content: "\e929";
}
.icon-tt-message:before {
  content: "\e937";
}
.icon-tt-plus:before {
  content: "\e924";
}
.icon-closed-envelope:before {
  content: "\e922";
}
.icon-template:before {
  content: "\e923";
}
.icon-add:before {
  content: "\e920";
}
.icon-filter-lines:before {
  content: "\e914";
}
.icon-group:before {
  content: "\e915";
  color: #8d8d8d;
}
.icon-group-selected .path1:before {
  content: "\e90c";
  color: rgb(44, 196, 12);
}
.icon-group-selected .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(141, 141, 141);
}
.icon-group-selected .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(141, 141, 141);
}
.icon-group-selected .path4:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(141, 141, 141);
}
.icon-group-selected .path5:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(141, 141, 141);
}
.icon-group-selected .path6:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(44, 196, 12);
}
.icon-group-selected .path7:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(44, 196, 12);
}
.icon-group-selected .path8:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(44, 196, 12);
}
.icon-cloud-error:before {
  content: "\e90a";
}
.icon-view-list:before {
  content: "\e908";
}
.icon-view-grid:before {
  content: "\e909";
}
.icon-stats-bars1:before {
  content: "\e900";
}
.icon-stats-bars2:before {
  content: "\e901";
}
.icon-stats-bars3:before {
  content: "\e902";
}
.icon-stats-bars4:before {
  content: "\e903";
}
.icon-stats-bars5:before {
  content: "\e904";
}
.icon-right-wrench:before {
  content: "\e905";
}
.icon-utils:before {
  content: "\e906";
}
.icon-paper-plane-ic .path1:before {
  content: "\e916";
  color: rgb(219, 236, 255);
}
.icon-paper-plane-ic .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(238, 246, 255);
}
.icon-paper-plane-ic .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(199, 225, 255);
}
.icon-paper-plane-ic .path4:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path5:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path6:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path7:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path8:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path9:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-paper-plane-ic .path10:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-checkmark-full:before {
  content: "\ea10";
}
.icon-star:before {
  content: "\e9d7";
}
.icon-heart-full:before {
  content: "\e9db";
}
.icon-heart:before {
  content: "\e9dc";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-smile-full:before {
  content: "\e9e2";
}
.icon-checkmark:before {
  content: "\ea12";
}
.icon-library:before {
  content: "\e921";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-contrast:before {
  content: "\e9d5";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-infinite:before {
  content: "\ea2f";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-paragraph-left:before {
  content: "\ea77";
}
.icon-spinner:before {
  content: "\e984";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-cog:before {
  content: "\e994";
}
.icon-embed:before {
  content: "\ea7f";
}
.icon-quill:before {
  content: "\e907";
}
.icon-undo:before {
  content: "\e967";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-stats-bars:before {
  content: "\e99d";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-tab-sort:before {
  content: "\ea45";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-sort-numeric-asc:before {
  content: "\ea4a";
}
.icon-sort-numberic-desc:before {
  content: "\ea4b";
}
.icon-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-droplet:before {
  content: "\e90b";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin-full:before {
  content: "\e9ad";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-price-tags:before {
  content: "\e936";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-share:before {
  content: "\ea7d";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-minus:before {
  content: "\ea0c";
}
.icon-loop:before {
  content: "\ea2e";
}
.icon-filter:before {
  content: "\ea5b";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-mail:before {
  content: "\ea83";
}
.icon-mail2:before {
  content: "\ea84";
}
.icon-mail3:before {
  content: "\ea85";
}
.icon-mail4:before {
  content: "\ea86";
}
