@import "../global-propierties";

.editor-toolbar {
  display: flex;
  flex: 1;
}

.toolbar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: inset -1px 0 rgba(0,0,0,.07);
  padding-bottom: 16px;
  flex-direction: column;
  box-sizing: border-box;
  box-sizing: border-box;
  width: 250px;
  background-color: $pickled-bluewood;

  &--list{
    margin-left: 0px;
    transition: padding .3s ease-in-out;

    .external-links {
      margin: 1rem 0;
      display: flex;
      justify-content: flex-end;
      
      a {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        box-shadow: .5px 0.5px 4px rgba(199, 232, 254, 0.19);
        margin-right: .51rem;
        color: #C7E8FE;
        border-radius: 4px;

        &:hover {
          box-shadow: .5px 0.5px 4px rgba(199, 232, 254, 0.1);
        }
      }
    }
  }

  &__logo{
    align-items: center;
    height: 6rem;
    background: hsla(0,0%,100%,.05);
    position: relative;
    display: flex;
    justify-content: center;
  }

  h3 {
    color: #DDD;
    font-size: 1.2rem;
    font-family: Montserrat;
    font-weight: bold;
  }

  img {
    height: 3rem;
    margin-right: 1rem;
  }

  &__style--button {
    position: absolute;
    bottom: 1rem;
    width: 250px;
  }
}

.toolbar-slider {
  width: 0;
  height: 100%;
  display: none;
  background-color: $toolbar-background;

  &--open {
    width: 256px;
    display: block;
    border-right: 1px solid rgba(0,0,0,.07);
    overflow-y: auto;
  }

  &--medium {
    width: 360px;
  }
  &--big {
    width: auto;
    flex: 1;
  }
}

.toolbar-item {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
 // width: 64px;
  height: 48px;
  color: $color-grey-3;
  font-size: 18px;

  &.selected {
    &::before {
      background: $color-grey-4;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: -1;
    border-radius: 3px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:hover {
    opacity: .8;
    transition: .2s ease-in-out;
  }

  & > span {
    display: flex;
  }
}