.profiling {
  display: flex;

  .profile {
    width: 300px;
    min-height: 300px;
    background: #0b3d84;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-size: cover;
      border: 2px solid #ffffff;
      box-sizing: border-box;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffd782;
      font-weight: 700;
      font-size: 20px;
      background: #6d7f92;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &__infos {
      // used on responsive don not remove it from html
    }

    .text {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #fff;
    }

    .title_text {
      font-weight: 500;
      font-size: 18px;
    }

    .subtitle_text {
      color: #c7e8fe;
    }

    .info {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #ffd782;
      padding: 0.5rem 1rem;
    }
  }

  .profile_details {
    flex: 1;
    background: #ffffff;
    border-radius: 0 4px 4px 0;

    .profile_header {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      margin-top: 1rem;

      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #0b3d84;
      }
    }
    .profile_form {
      padding: 0 2rem;
      padding-bottom: 2rem;

      .profile_field {
        padding-top: 1rem;

        .header {
          font-size: 14px;
          line-height: 16px;
          color: #3c4e64;
          padding: 1rem;
        }

        .footer {
          font-weight: normal;
          font-size: 12px;
          color: #18a0fb;
          padding: 0.5rem 1rem;

          &.alert {
            color: #FE3745;
          }
        }

        .active {
          border: 1px solid #18a0fb;
          border-radius: 4px;
          transition: border 0.3s ease-in-out;
        }
      }
    }
  }
}

.gender {
  display: flex;
  padding-bottom: 1rem;

  & > div {
    display: flex;
    align-items: center;
  }

  &_button {
    background: #b2bcc6;
    border: 3px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 30px;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &_selected {
      background: #18a0fb;
      border: 3px solid #c7e8fe;
    }
  }
}

.input_field {
  background: #f8f9fa;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 16px;
  color: #29394d;
  box-shadow: none;
  outline: none;
  margin: 0;

  &.has_value {
    background: #f3faff;
    border-color: #c7e8fe;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #c7e8fe;
    background: #f3faff;
  }

  &:invalid {
    border-color: #ffabab;
    background: #fff7f7;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 640px) {
  .profiling {
    flex-direction: column;
    .profile {
      width: unset;
      min-height: unset;
      flex-direction: row;
      padding: 2rem;

      .avatar {
        margin-right: 2rem;
      }

      &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > * {
          padding: 0 1rem;
        }
      }

      .info {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .profiling {
    flex-direction: column;
    .profile {
      width: unset;
      min-height: unset;
      padding: 2rem;
    }
  }
}
