.react-tagsinput--focused {
  border-color: $main-medium-gray;
}

a.react-tagsinput-remove {
  color: $main-color;
  font-size: 1rem;
}

.react-tagsinput-input {
  margin-bottom: 0;
}

.react-tagsinput-tag {
  border-radius: 0px;
  border: none;
  color: $main-color;
  font-family: $tag-font-family;
  background-color: $tag-bg-color;
  margin: 0.3rem;
  display: inline-block;
  padding: $tag-padding;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: none;
}
