$cd-filter-width: 340px;

#ttp-horizontal-filter {
  margin: 1rem 0;

  select {
    text-transform: uppercase;
  }

  .filter-wrapper {
    display: flex;
    justify-content: flex-end;
    border-radius: 3px;
    transition: all .3s;

    .filter-button {
      width: 130px;
      height: 32px;
      transition: all .3s;
      background: #F1F2F4;
      border-radius: 30px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $pickled-bluewood;
      text-transform: uppercase;
      display: flex;
      justify-content: space-around;
      align-items: center;
      outline: none;
      cursor: pointer;
      svg {
        fill: #6D7F92;
        &.filter-icon-white {
          fill: #FFFFFF;
        }
      }
    }
  }
  .filter-form {
    display: flex;
    align-items: baseline;
    text-transform: uppercase;
    margin: auto;
    border-radius: 4px 0px 4px 4px;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    position: relative;
    padding: 0 0 3rem 0;
    z-index: 2;
    margin-bottom: 2rem;
    margin-top: 12px;

    &-enter {
      opacity: 0;
      transform: translateY(-2.5rem);
    }

    &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms, height .3s;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translateY(-2.5rem);
      transition: opacity 300ms, transform 300ms, .3s;
    }

    span {
      position: relative;
      display: inline-block;
    }

    .close-button {
      position: absolute;
      right: 0;
      font-size: 1.2em;
      line-height: 1;
      background-color: white;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .ttp-select {
      span[aria-live="assertive"] {
        display: none;
      }
    }
  }
  label{
    font-family: $main-body-font;
    margin-top: .5rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    span.lbl {
      padding: 9px;
    }
  }

  &.is-fixed {
    .filter-wrapper {
      position: fixed;
      background: #fefefe;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border-radius: 0;
    }
    &.ttp-medium-9 {
      .filter-wrapper {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
  .filter-close {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: #F1F2F4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: -1;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &.ttp-horizontal-filter__open {

    .filter-button {
      background-color: $slate-gray;
      color: #FFFFFF;
    }

    .filter-form {
      background: #FAFBFB;
    }

    .empty-box .ttp-select__control {
      background-color: #fff;
    }

    &.light .filter-form {
      background-color: #fff;
      .empty-box .ttp-select__control {
        background: #FAFBFB;
      }
    }
  }

  .DateRangePicker {
    transform: translateX(-.6rem);
    padding-left: .6rem;
    width: calc(100% + .6rem);

    text-transform: uppercase;
    color: #6D7F92;

    .DateRangePickerInput {
      border: none;
      background-color: inherit;

      .DateInput {
        width: 45%;

        .DateInput_input {
          margin: 0;
          width: 100%;
          padding: 11px 11px 9px;
          box-shadow: none;
          border-radius: 4px;
          border: 1px solid hsl(0,0%,80%);
          height: 38px;
          &__focused {
            outline: 0;
            background: #fff;
            border: 2px solid #2684FF;
          }
          &::placeholder {
            color: $slate-gray;
            text-transform: uppercase;
            font-size: .8rem;
          }
        }
      }
    }

    .CalendarMonth_caption {
      padding-top: 30px;
    }

    .DateRangePickerInput_arrow_svg {
      fill: $slate-gray;
      height: 15px;
    }

    .DateRangePickerInput_clearDates {
      margin: 0;

    }

    .dark {
      .DateRangePickerInput_clearDates {
        background-color: #FAFBFB;
      }
    }

    .CalendarDay__selected_span {
      background: #1779bace;
    }
    
    .CalendarDay__selected {
      background: #14679e;
    }
    
    .CalendarDay__selected:hover {
      background: #1779ba;
    }

    .CalendarDay__selected_span,
    .CalendarDay__selected,
    .CalendarDay__selected:hover {
      border: 1px solid #14679e;
      color: white;
    }
    
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
      background: #1779babe;
      border: none;
      color: white;
    }
  }
  &.dark {
    .DateRangePickerInput {
      background: #FAFBFB;
    }
    .empty-box, .empty-box input{
      background: #FFF;
      .ttp-select {
          &__control {
              background: #FFF;
          }
      }
    }
    .empty-date-picker {
      .DateInput {
        background: #FFF;
      }
    }
  }
}
