.breadcrumb {
    font-size : 12px;
    margin    : 0 0 20px;
    position  : absolute;
    top       : -2rem;

    .bread-link {
        color            : #8d8d8d !important;
        text-decoration  : none;
        word-wrap        : break-word;
        background-color : rgba(0, 0, 0, 0);
        text-transform   : uppercase;
        cursor: pointer;
    }

    .is-disabled .bread-link {
        cursor: not-allowed;
        pointer-events : none;
        opacity        : 0.4;
    }

    .bread-current {
        font-weight : bold;
    }

    .bread-separator {
        font-size : 0;

        &:after {
            display                 : inline-block;
            font-family             : 'simple-line-icons' !important;
            speak                   : none;
            font-style              : normal;
            font-weight             : normal;
            font-variant            : normal;
            text-transform          : none;
            line-height             : 1;
            -webkit-font-smoothing  : antialiased;
            -moz-osx-font-smoothing : grayscale;
            content                 : "\e606";
            font-size               : 10px;
            padding                 : 0 12px;
            box-sizing              : border-box;
        }
    }
}
