.survey-type__selector {
  .image-header {
    height: 30rem;
    background-image: url('/img/sms-stats-page.jpg') !important;
    background-size: cover;
  }
  .tabs-wrapper {
    margin-top: 3.5rem;
    li.active {
      color: #fff;
      margin-bottom: -1px;
      border-bottom: 4px solid #5080be;
    }
  }
  .boxes {
    background-color: #fff;
    min-height: 71vh;
    background: #fafbfb;
    padding: 4rem 0 0;
    max-width: 100%;

    &--padding {
      padding-bottom: 10%;
    }

    &.centered {
      min-height: unset;
      margin: 4rem 1rem;
      padding: 4rem 3rem;
      border-radius: 4px;
    }
  }

  .srv-card__container {
    justify-content: center;
    margin: 0;
    &::before {
      border-radius: 4px;
    }
  }
}

.template > .filter-wrapper {
  margin-bottom: 1rem;
}

.template > .filter-wrapper .filter-wrapper {
  margin-left: 0;
}
