@import "../global-propierties";

.srv {
  &__Title{

    textarea{
      padding-right: 65px;
    }
  }

  &__select {

    &__lng{
      font-weight: 700;
      color: #ffffff;
      background: #7f8080;
      position: absolute;
      width: 52px;
      height: 24px;
      font-size: $font-size-body;
      right: 15px;
      top: 15px;
      border-radius: 2px;
      padding: 0 8px;

      optgroup {
        background: #5b6671;
        font-size: $font-size-body;
      }
      &:focus{
        background: #5b6671;
      }

      //&:after{
      //  content: '\f078';
      //  font: normal normal normal 17px/1 FontAwesome;
      //  color: #0ebeff;
      //  right: 11px;
      //  top: 6px;
      //  height: 34px;
      //  padding: 15px 0px 0px 8px;
      //  border-left: 1px solid #0ebeff;
      //  position: absolute;
      //  pointer-events: none;
      //}
    }

  }
}
