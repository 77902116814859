.result-card {
  position: relative; // box-shadow: 0 15px 45px -9px rgba(0, 0, 0, .2);
  box-shadow: -1px 5px 14px 2px rgba(0, 0, 0, 0.1);
  transition: all .3s ease-in-out; // padding: .5rem 1rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .06);
    bottom: -2px;
  }

  .card-title {
    // margin-top: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
    font-family: Raleway, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    color: #3f3f3f;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
  }

  .card-score {
    display: flex;
    margin-top: 1.5rem;

    .score-item {
      flex: 1;
      text-align: center;
      color: #8d8d8d;

      &:first-child {
        border-right: 1px rgba(233, 235, 238, 0.8) solid;
      }
    }
  }
}

.score-label {
  justify-content: center;
  align-items: center;
  display: flex;

  &>label {
    background: #f3f3f3;
    border: #e9ebee 1px solid;
    border-radius: 30px;
    width: 80px;
    color: #8d8d8d;
    font-size: 13px;
  }
}

.score-score {
  font-size: 1.2rem;
  margin-top: .5rem;
}

.result-row {
  padding: 1rem 1.5rem;
  padding-right: 0; // box-shadow: 0 3px 13px 1px rgba(0, 0, 0, .12);
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .06);
  border-left: .2rem solid #7f8080;
  margin-bottom: 1rem;
  cursor: pointer;

  &__header-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__header {
    font-size: 1rem;
    line-height: 1.4;
    font-family: Raleway, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    color: #3f3f3f;
    font-weight: 700;
  }

  &__score-item {
    text-align: center;
  }

  .separator {
    border-right: 1px rgba(233, 235, 238, 0.8) solid;
  }

  &:not(:las-child) {
    border-right: 1px rgba(233, 235, 238, 0.8) solid;
  }
}