#image-selector {

  .attachments-container {
    margin: 2rem 4rem;
  }

  .uploader-container {
    input[type="text"] {
      border: none;
      box-shadow: none;
      margin: 0;
    }
    button {
      margin: 0;
    }
    .save-container {
      border: 1px solid $main-medium-gray;
    }

    &.spacer {
      padding-top: 50px;
    }
  }

  .cover-buttons {
    justify-content : center;
    align-items: center;
    color: #000;
    transition: nth($transitions, 3);
    height: auto;
    overflow: hidden;
    padding: 0.5rem 1rem;
    box-shadow: $shadow-like-border;
    text-align: center;

    button {
      color: rgba(0,0,0,.55);
      font-weight: 600;
      height: 32px;
      padding: 0 16px;
      cursor: pointer;
      margin-right: 8px;
      text-transform: uppercase;

      &.button-cancel {
        box-shadow: $shadow-like-border;
        font-weight: 400;
        margin-right: 0;
        background-color: transparent;
      }

      &.button-upload {
        color: #fff;
        display: flex;
        align-items: center;
      }
    }
  }

  .image-container {
    position: relative;
    min-height: 20rem;
    width: 100%;
    background-color: $main-light-gray;
    text-align: center;
    cursor: pointer;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px dashed $main-medium-gray;
    max-height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .image-iconbox{
      z-index: 2;
      .icon {
        font-size: 6rem;
        color: #fff;
        opacity: 0.6;
        transition: nth($transitions, 3);
      }

      &:hover {
        .icon {
          transition: nth($transitions, 3);
          opacity: 1;
        }
      }

      .icon-picture, .icon-camera{
        border: 2px solid #fff;
        border-radius: 2px;
        padding: 0.5rem;
        display: inline-block;
      }

      .icon-picture{
        -webkit-transform:rotate(-20deg);
        -moz-transform:rotate(-20deg);
        -o-transform:rotate(-20deg);
        transform: rotate(-20deg);
      }
      .icon-camera{
        -webkit-transform:rotate(20deg);
        -moz-transform:rotate(20deg);
        -o-transform:rotate(20deg);
        transform: rotate(20deg);
      }

      .icon-plus {
        font-size: 4rem;
        padding: 0.5rem;
      }
    }
  }


  .gallery {
    display: flex;
    text-align: center;
    
    &-item {
      background-size: cover;
      cursor: pointer;
      height: 15rem;
      margin: .5rem;
      display: flex;
      align-items: flex-end;
      border: 1px solid #d3d3d3;
      border-radius: 2px;
      p {
        background-color: rgba(0,0,0,.4);
        margin: 0;
        padding: 1rem 2rem;
        width: 100%;
        font-size: 1rem;
        color: #fff;
        font-weight: 700;
        text-align: left;
        opacity: 1;
        transition: all .5s linear;
      }
      &:hover{
        border: 2px solid #5081be;
        p {
          opacity: 0;
        }
      }
    }
  }
  .app-switch {
    margin-left: .5rem;
  }
}
