.stoolbar {
  background-color: #fefefe;
}

:global .toolbar-slider--medium {
  overflow-y: hidden;
}

.toolbar_content {
  overflow-y: auto;
  background: rgba(109, 127, 146, 0.2);
}

.tabs {
  display: flex;
  box-shadow: 0 -1px rgba(0, 0, 0, 0.07) inset;

  & > div {
    flex: 1;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #29394d;
    transition: box-shadow 0.5s ease-in-out;

    &.selected {
      box-shadow: inset 0 -2px 0 0 #29394d;
    }

    &:hover {
      background-color: #f8f9fa;
    }
  }
}

.block {
  cursor: pointer;
  display: flex;
  padding: 1rem 0.5rem;
  margin: 1rem 0.5rem;

  background: #ffffff;
  border: 1px solid #c7e8fe;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
  border-radius: 3px;

  &:hover {
    border-color: #18a0fb;
    background: #f3faff;
  }

  &.selected {
    background: #f3faff;
  }

  &__logo {
    & > span {
      height: 1.25rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: #fefefe;
      font-size: 12px;
    }
  }

  &__content {
    flex: 1;
    padding-left: 1rem;
  }

  &__icon {
    color: #18a0fb;
    padding-left: 1rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
