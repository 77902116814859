.styled-button {
  border: none;
  background: none;
  color: white;
  height: 50px;
  padding: 0;
  outline: none;
  z-index: 10;

  &:hover {
      background: none;
  }

  span {
    display: block;
    position: relative;
    line-height: 50px;
    height: 50px;
    cursor: pointer;
  }

  &>span:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 0;
    border: 1px solid white;
    border-left: none;
    border-bottom: none;

    transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
  }

  &:hover>span:after {
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
  }

  &>span:before {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    width: 20px;
    height: 0;

    border: 1px solid white;
    border-right: none;
    border-bottom: none;

    transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
  }

  &:hover>span:before {
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
  }

  &>span>span:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 1px;
    height: 1px;
    opacity: 0;

  }

  &:hover>span>span:before {
    opacity: 1;
    border-bottom: 1px solid white;
    width: calc(50%);
    height: 1px;
    transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
  }

  &>span>span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 1px;
    height: 1px;
    opacity: 0;

  }

  &:hover>span>span:after {
    opacity: 1;
    border-bottom: 1px solid white;
    width: calc(50%);
    height: 1px;
    transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
  }
}
