#theme-preview_close {
  z-index: 10;
  right: 50px;
}

#theme-preview {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: #373c44;
  position: fixed;
  width: 100%;
  height: 100%;

  %basic-content {
    height: 600px;
    background: #ccf;
    position: absolute;
  }

  #frame {
    width: 100%;
    height: 100%;
    border-width: 0;
  }

  div {
    display: inline-block;
  }

  .container-desktop {
    position: fixed;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 990px;
    height: 600px;
    background-image: url('/img/theme/mac.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;

    .content {
      @extend %basic-content;
      height: 471px;
      width: 751px; 
      top: 35px;
      left: 120px;
    }
  }


  .container-smartphone {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 400px;
    height: 600px;
    background-image: url('/img/theme/iphone.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;

    .content {
      @extend %basic-content;
      height: 433px;
      width: 246px;
      top: 68px;
      left: 76px;

      #frame {
        -webkit-transform: scale(.7, .7);
        -moz-transform: scale(.7, .7);
        -o-transform: scale(.7, .7);
        -ms-transform: scale(.7, .7);
        transform: scale(.7, .7);
        transform-origin: 0 0;
        overflow: hidden;
        width: 144%;
        height: 144%;
      }
    }
  }
}