.rank-organization {
  justify-content: center;
  padding-top: 5rem;
  .wrapper {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;

    .rank{
      font-size: 100px;
      line-height: 1;
      font-weight: bold;
    }
    .text{
      font-size: 30px;
      line-height: 1;
    }
    .rank-line{
      border: 10px solid #e9ebee;
      width: 75%;
      margin-top: 2rem;
    }
  }
}