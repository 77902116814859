.srv-filter {

  &__block {

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      font-size: $font-size-body;
      select {
        margin-left: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    button {
      margin: 0;
    }
  }
  .title {
    padding-right: 10px;
  }
}

.srv-flex{
  display: flex;

  &--center {
    justify-content: center;
    align-items: center;
  }
}
