.ttp-select {
  &.uppercase {
    .ttp-select__menu,
    .ttp-select__value-container {
      text-transform: uppercase;
    }
  }

  .ttp-select__control {
    background: #f3faff;
    border: 1px solid #c7e8fe;
    box-sizing: border-box;
    border-radius: 4px;

    &--is-focused {
      box-shadow: none;
      border-color: #c7e8fe !important;
    }

    &:hover {
      border-color: #c7e8fe !important;
    }
  }

  &.empty-box {
    .ttp-select__control {
      background: #f8f9fa;
      border-color: #f1f2f4;
    }
  }

  .ttp-select__value-container {
    padding: 6px 8px;
  }

  &__multi-value {
    background-color: #f3faff !important;
  }

  &__option {
    &--is-selected,
    &--is-selected:hover {
      background: #c7e8fe !important;
      color: #3c4e64 !important;
    }
    &:hover {
      background: #f3faff;
    }
  }

  &.min-width-200 {
    min-width: 200px;
  }
}
.ttp-select__input input[type='text'] {
  height: auto;
  margin: 0;
  box-shadow: none;
}

.error .ttp-select {
  &__control {
    background: #f9ecea;
    border: 1px solid #cc4b37;
  }
}

// TODO to be moved. Class used in Settings survey: <Select />
.filled-box {
  background: #f3faff;
  border: 1px solid #f3faff;
  box-sizing: border-box;
  border-radius: 4px;
  color: $pickled-bluewood;
  .ttp-select__value-container {
    color: $pickled-bluewood;
    font-weight: normal;
  }
  .ttp-select {
    &__control {
      // background: #F3FAFF;
      border: 1px solid #f3faff;
    }
    &__dropdown-indicator {
      color: $pickled-bluewood;
    }
    &__indicator-separator {
      background-color: $pickled-bluewood;
    }
  }
}
