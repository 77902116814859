.gradiant {
  background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
  background-size: 300% 100%;

  &.blue {
    background: linear-gradient(to right, #ffffff, #f2f7fc)
  }
}

.gradiant-dark {
  background: linear-gradient(to right, #fafafa, #cacaca, #767676, #DEDEDE);
  background-size: 300% 100%;
  min-height: 8px;
}

.list-fetching {
  &--mb-mode {
    mix-blend-mode: normal !important;
    background: none !important;
  }
}

.list-fetching,
.fetching {

  h5,
  h2 {
    height: 1rem;
    width: 70%;
    margin-bottom: .7rem;
    mix-blend-mode: normal;
  }

  .no-content {
    height: 1.5rem;
  }
}

.srv-prv-fetching {
  display: flex;
  justify-content: center;

  h1 {
    height: 1rem;
    margin-bottom: .7rem;
    width: 300px;
  }
}

.setting-fetching {
  display: flex;
  flex-wrap: wrap;
  width: 70%;

  .user-fetching {
    width: 33.33%;
  }
}

.user-fetching {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .user-avatar {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  h3 {
    height: 1.3rem;
    width: 60%;
    margin-bottom: .7rem;
  }

  h4 {
    height: 1rem;
    width: 70%;
    margin-bottom: .7rem;
  }

  span {
    width: 20%;
    height: 1px;
    background-color: $main-orange;
    margin-bottom: .7rem;
  }

  h5 {
    height: .8rem;
    width: 50%;
  }

  h2 {
    height: 1.5rem;
    width: 70%;
  }
}

.generic-email-fetching {
  h6 {
    width: 30rem;
  }
  h6, h3, h4, h5{
    height: 1rem;
  }
  h3{
    width: 20rem
  }
  h5{
    width: 10rem
  }
}