@import "global-propierties";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

%title-shared {
  text-align: left;
  display: flex;
  justify-content: center;
  width: 100%;
}

.srv {
  &__wrapper {
    flex: 1 1 auto;
  }

  &__menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: $font-size-h2;
    }
  }

  &__create {
    padding-left: 0.6rem;
    font-size: $font-size-h3;
  }

  &--small-font {
    font-size: $font-size-body;
  }

  &--btn-label {
    font-weight: 500;
    font-size: $font-size-h3;
    margin: 0 5px;
    line-height: 25px;
    justify-content: space-between;
    align-items: center;
    display: flex;

    & > :first-child{
      margin-right: 5px;
    }
  }

  &__add-button {
    border-radius: 2px !important;
  }
}

.srv-list-no-data {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  margin: 2rem 0rem;
  align-items: center;
  flex-direction: column;

  &__icon {
    font-size: 120pt;
    line-height: 1;
    opacity: 0.5;
  }

  &__msg {
    font-weight: bold;
    color: #2c2b3f;
    font-size: 30px;
  }
}

.srv-list .srv-list-filter {
  padding: 1rem 0;
}

.srv__wrapper .srv-list-filter {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.srv-list-item {
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }

  &--colored {
    background-color: antiquewhite;
  }

  .label {
    border-radius: 30px;
  }

  .survey-status {
    .date-wrapper {
      background: #f3faff;
      padding: .2rem .5rem;
      border-radius: 4px;
      margin-top: 5px;
      font-family: Roboto;
      font-size: .8rem;
      font-weight: normal;

      span { 
        &:first-child {
          padding-right: 5px;
        }
      }
    }
  }
}

.srv-list-item.colored {
  background-color: antiquewhite;

  &:hover {
    background-color: rgba(antiquewhite, .7);
  }
}

.srv-list-filter>.filter-wrapper {
  margin-left: 0;
}

.srv-list-filter>.filter-wrapper {
  select {
    margin-bottom: 0;
  }
}

.srv-list-filter>.filter-wrapper .filter-button {
  margin-bottom: 0;
}

.srv-view-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ccc;
  box-shadow: -1px 0 #CCC inset;
  padding-right: 20px;
  margin-bottom: 16px;

  padding-left: 4px;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__item {
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:hover {
      color: $color-grey-2;
    }

    &.is-active {
      color: #2c2b3f;
    }
  }
}

.srv-list-actions .drop-down-option>.srv__drop-down--icon {
  transform: rotate(90deg);
}

.srv-list-margin {
  margin: 1rem 2rem;
}

.srv-list-bottom-page {
  padding-bottom: 15%
}

.srv-list {
  margin-bottom: 6rem;

  &-list {
    padding-top: 1rem;

    table th label {
      font-weight: bold;
      background: white;
      display: inline-block;
      border-radius: 30px;
      padding: 0 1rem;

      &:hover {
        overflow: visible;
      }
    }

    table th .number {
      max-width: 150px;
    }
  }

  .ttp-datatable__row {
    &:hover {
      background-color: rgba(243, 243, 243, 0.6);

      .drop-down-option {
        display: inline-block;
      }
    }

    .drop-down-option {
      display: none
    }
  }

  .ttp-datatable__header {
    background-color: #3182bd;
    color: #FFF;
    opacity: .8;
  }
}

.srv {
  &-card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 235px;
    padding: 0;
    overflow: visible !important;
    cursor: pointer;
    animation: fadeIn 0.1s ease-in-out;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 2px 12px rgba(0, 0, 0, .06), 0 8px 14px rgba(0, 0, 0, .04), 0 12px 16px rgba(0, 0, 0, .02);
      transition: box-shadow .3s ease-in-out;
    }

    &__container {
      flex: 1;
      margin: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      vertical-align: middle;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
      }
    }

    &--background-img {
      background: #2c2e26 url('/img/survey/srv-background.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &__info {
      padding: 5px;
      justify-content: space-between;
      vertical-align: center;
      border-radius: 0px 0px 2px 2px;
    }

    &__label {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: center;
      background-color: #f2f2f2;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      color: #747474;
    }

    &__title {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      z-index: 1;

      h5 {
        font-family: "Roboto","Helvetica","Arial",sans-serif;;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        text-align: center;
        margin: 8px 5px;
      }

      &--fetching{
        padding: 5px;
      }
    }

    &__title-type {
      color: #000000;
      @extend %title-shared;
    }

    &__title-fetching {
      color: #FFFF;
      text-align: left;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__font-bold {
    font-weight: bold;
    text-shadow: 0 0 5px black;
    color: #ffffff;

    &--fetching{
      width: auto !important;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap; // margin: 1rem 2rem;
    // padding-bottom: 20%;
  }

  &__drop-down {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    margin: 0;
    width: 28px;
    height: 28px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .07);
      border-radius: 2px;
    }

    &--icon {
      color: $color-grey-1;
      display: flex;
      justify-content: center;
    }
  }

  &--hover {
    background-color: rgba(0, 0, 0, .07);
    border-radius: 2px;

    &__font-bold {
      font-weight: 900 !important;
    }
  }

  &__title-template {
    margin-top: 40px;
    margin-left: 80px;
  }

  &--padding {
    padding-top: 30px;
  }
}

.margin-top-auto {
  margin-top: auto;
}

.margin-left-auto {
  margin-left: 10em;
}

.disabled-link {
  color: currentColor;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.status-wrapper {
  position: relative;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
  margin-top: 10px;

  & > :not(:first-child) {
    margin-top: 5px
  }
}

.status {
  font-size: 11px;
  z-index: 3;
  letter-spacing: 1px;
  border-radius: 10px;
  line-height: 15px;
  background: #fff;
  padding: 4px 10px 4px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 2px 12px rgba(0, 0, 0, .06);
  max-width: 135px;

  &--right {
    right: 0;
  }
}

.sectorial-icon {
  font-size: $icon-font-size-big;
}