@keyframes podium-header {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes podium-medal {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }

  30% {
    transform: rotate3d(1, 0, 0, 30deg);
  }

  80% {
    transform: rotate3d(1, 0, 0, -10deg);
  }

  100% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

@keyframes podium-content {
  0% {
    transform: translate3d(0px, 100%, 0px);
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.quiz-actions {
  padding: 0 1rem;
  margin: 0 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  height: 4.6rem;
  align-items: center;
  background: #FFF;
  padding: 0 1.5rem;
  border-radius: 4px;

  .game-pin {
    font-family: Roboto;
    color: $pickled-bluewood;
    font-weight: normal;

    span {
      &:first-child { 
        margin-right: .5rem;
        font-size: 1rem;
      }
    }

    .pin {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}

.quiz-wrapper {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }

  .question-wrapper {
    height: calc(100% - 6rem);
    margin-top: 1rem;

    &.fh {
      height: 100%;
    }

    .container {
      height: calc(100% - 1rem);
      position: relative;
      display: flex;
      flex-flow: column;
    }
  }


  .bilingual {
    .lng {
      padding: 5px;
      background: #f1f2f4;
      width: 160px;
      border-radius: 4px 4px 0 0;
      
      img {
        margin-right: 1rem;
        margin-left: .5rem;
      }
      span {
        font-family: Roboto;
        color: #28394d;
        font-size: .8rem;
        text-transform: uppercase;
      }
    }
  }

  .left-side {
    .question-content .choices-block .choice-block .icon-block { display: none };
    .question-content .choices-block { padding-left: 1rem }
    .question-content .title-block .count-down {
      position: absolute;
      left: calc(100% - 1rem - 4px); // 4px = (8px / 2) of shadow / 1rem of padding
      z-index: 2;
      background: #FFF;
      margin-left: 0;
      box-shadow: 0 0 0px 8px #ffffff;
    }
    .question-content.bilingual .title-block { padding-right: 2.5rem; }
  }

  .right-side {
    .question-content .choices-block .choice-block .icon-block {
      position: absolute;
      margin: 0;
      right: calc(100% + 22.5px + 4px);
      top: calc(50% - 2.25rem);
      box-shadow: 0 0 0px 8px #ffffff;
      border-radius: 0px 40px;
      height: 4.5rem;
      width: 45px;
      padding: 0;

      .stats-percent {
        color: #28394d;
        font-size: 1rem;
        font-weight: 700;
        span { margin-top: 8px; }
      }

      .wrapper { padding: 0 .6rem; }
    };
    .question-content .choices-block { padding-right: 1rem }
    .question-content.bilingual .title-block { padding-left: 2.5rem; }
  }
}

.quiz-preview {
  margin-top: 1rem;
  border-radius: 4px;
  flex: 1;

  &__dimension {
    background: #C7E8FE;
    margin-bottom: 2rem;
    min-height: calc(100% - 1rem);
    color: #29394d;
    font-family: Roboto;
    padding-top: .5px; // to activate label margin
    border-radius: 4px;

    label {
      font-size: 1.5rem;
      background: #F3FAFF;
      margin: 1rem 0;
      background: #FFF;
      padding: 0 1rem;
      border-radius: 4px;
      margin: 1rem;

      .lng { // TODO
        background: transparent !important;
        padding: 0 !important;

        span { font-size: 1rem !important; }
      }
    }

    .text {
      font-size: 5rem;
      text-align: center;
      color: #29394d;
      margin-top: 5rem;
      padding: 1rem;
    }

    &.bilingual {
      text {
        font-size: 4rem;
      }
    }
  }

  &__result {
    background: #F1F2F4;
    height: calc(100% - 1rem);
    color: #29394d;
    font-family: Roboto;
    padding-top: 1rem;

    .result-header {
      font-size: 2.5rem;
      color: $dodger-blue;
      margin-bottom: 2rem;
      text-align: center;
      text-transform: uppercase;
    }

    .ranking {
      padding: 1rem;

      .ranking-content {
        .participant {
          margin: 1rem;
          padding-bottom: 0.8rem;
          display: flex;
          justify-content:space-between;
          box-shadow: rgba(0, 0, 0, 0.15) 0px -3px inset;

          &:not(.fetching) {
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
              transform: scale3d(0, 0, 0);
              animation: 0.4s ease 0.85s 1 normal forwards running podium-header;
            }
          }

          .name { flex: 1 }
          .score {}
        }
      }
    }

    .result-winners {
      padding: 1rem;
      display: flex;
      flex-direction: column;

      .podium-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .podium {
        display: flex;
        align-items: flex-end;

        .podium-block {
          width: 16rem;
          max-width: 16rem;
          position: relative;
          font-size: 1.75rem;
          font-weight: 400;
          line-height: 2.25rem;
          flex: 1 0 auto;
          margin: 0px;
          padding: 0.5rem 1rem 0px;

          &:nth-child(1) {
            order: 2;

            .podium-block__content {
              height: 40vmin;
              min-height: 9.25rem;
            }
          }
          &:nth-child(2) {
            order: 1;

            .podium-block__content {
              height: 30vmin;
              min-height: 8.25rem;
            }
          }
          &:nth-child(3) {
            order: 3;

            .podium-block__content {
              height: 25vmin;
              min-height: 7.25rem;
            }
          }

          .podium-block__header {
            position: relative;
            z-index: 1;
            perspective: 30rem;
            transform: scale3d(0, 0, 0);
            margin-bottom: 1rem;
            background: rgba(0, 0, 0, 0.1);
            animation: 0.4s ease 0.85s 1 normal forwards running podium-header;

            .podium-block__name {
              display: flex;
              justify-content: center;
              align-items: center;
              box-shadow: rgba(0, 0, 0, 0.15) 0px -0.1875rem inset;
              margin: 0px 0px 1.5rem;
              padding: 1rem;
              background: #3C4E64;
              border-radius: 0.25rem;

              span {
                color: rgb(255, 255, 255);
                line-height: 1.375;
                font-size: 1.8rem;
                font-weight: 400;
                text-align: center;
                word-break: break-word;
                overflow-wrap: break-word;
                hyphens: auto;
                overflow: hidden;
              }
            }
            .podium-block__medal {
              width: 4.5rem;
              left: calc(50% - 2.25rem);
              margin: 0;
              bottom: -3.7rem;

              position: absolute;
              transform-origin: center top;
              animation: 0.7s ease-in-out 1s 1 normal both running podium-medal;
            }
          }
  
          .podium-block__content {
            overflow: hidden;

            .container {
              height: 100%;
              box-shadow: rgba(0, 0, 0, 0.15) 0px -3px inset;
              background: #6D7F92;
              border-radius: 4px;
              animation: 0.6s cubic-bezier(0.4, 0.7, 0.4, 1) 0.15s 1 normal both running podium-content;
            }
            .score {
              color: rgb(255, 255, 255);
              font-size: 2rem;
              font-weight: 400;
              text-align: center;
              padding: 3rem .5rem 1rem;
              line-height: 2.25rem;
              margin: 0px 0px 1rem;
            }
          }
        }
      }
    }
  }

  &__question {
    background: #F1F2F4;
    border-radius: 4px;
    margin-bottom: 1rem;
    height: calc(100% - 1rem);
    color: #29394d;
    font-family: Roboto;

    &.question-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text {
        font-size: 3.5rem;
        font-weight: 600;
        padding: 1rem 3rem;
      }

      &.bilingual {
        .text { font-size: 2.5rem }
        height: calc(100% - 1rem);
        min-height: unset;
        flex: 1;
        border-top-left-radius: 0;
      }
    }

    &.question-content {
      .title-block {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        padding-bottom: 1.5rem;

        .title {
          font-size: 1.8rem;
        }

        .count-down { // stats-duplicate
          border: solid 2px #18a0fb;
          color: #18a0fb;
          font-weight: bold;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          margin-left: 1rem;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          flex-shrink: 0;
          align-self: flex-start;

          &.blue {
            border: solid 2px #18a0fb;
            color: #18a0fb;
          }
          &.orange {
            border: solid 2px #FFA800;
            color: #FFA800;
          }
          &.red {
            border: solid 2px #F04A4A;
            color: #F04A4A;
          }
        }
      }

      .choices-block {
        padding: 2rem;
        padding-top: 1rem;

        .choice-block {
          display: flex;
          margin-bottom: 1.3rem;

          .icon-block {
            border: 3px solid transparent;
            box-sizing: border-box;
            border-radius: 50px 50px 0px 50px;
            margin-right: 1rem;
            background: #FFFF;
            overflow: hidden;

            img { width: 25px }

            .wrapper {
              padding: 0 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #FFF;
              font-size: 1.55rem;
              height: 100%;
              width: 100%;
            }

            &.is-correct {
              box-shadow: 0 0 0px 8px #28394d;
              border: 0;
            }
          }

          .text-block {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            flex: 1;
            padding: .5rem 2rem;
            color: #FFFFFF;
            background: #29394D;
            position: relative;
            overflow: hidden;

            .content {
              font-size: 1.6rem;
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              padding: .5rem 0;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              z-index: 1;
              position: relative;
              display: flex;
              justify-content: space-between;
            }

            .back-ground {
              position: absolute;
              border-radius: 50px 0px 0px 50px;
              left: 0;
              top: 0;
              height: 100%;
              z-index: 0;
              transition: width 1.2s ease-in-out;
            }
          }

          .stats-block {
            background: #29394D;
            font-weight: bold;
            font-size: 1.2rem;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            margin-left: 1rem;
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          &.blue {
            .icon-block { .wrapper { background: #57CCF0; } }
            .text-block { .back-ground { background: #57CCF0; } }
            .stats-block { color: #57CCF0 }
          }

          &.orange {
            .icon-block { .wrapper { background: #FFA800; } }
            .text-block { .back-ground { background: #FFA800; } }
            .stats-block { color: #FFA800 }
          }

          &.red {
            .icon-block { .wrapper { background: #F04A4A; } }
            .text-block { .back-ground { background: #F04A4A; } }
            .stats-block { color: #F04A4A }
          }

          &.green {
            .icon-block { .wrapper { background: rgba(62, 194, 143, 0.95); } }
            .text-block { .back-ground { background: rgba(62, 194, 143, 0.95); } }
            .stats-block { color: rgba(62, 194, 143, 0.95) }
          }
        }
      }

      &.bilingual {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: unset;
        border-top-left-radius: 0;

        .title-block .title {
          font-size: 1.45rem;
          min-height: 4rem;
          display: flex;
          align-items: center;
        }
        .title-block {
          padding-bottom: 1rem;
        }

        .choices-block {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-bottom: 1rem;

          .choice-block {
            flex: 1;
            position: relative;

            .text-block {
              overflow: hidden;
              display: flex;
              align-items: center;

              .content {
                font-size: 1.3rem;
                padding: .2rem 0;
              }
            }
          }

        }
      }
    }

    &.question-ranking {
      justify-content: center;
      display: flex;
      flex-direction: column;

      .user-wrapper {
        background: #FFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        margin: 1rem 5rem;
        padding: .8rem 2rem;
        font-size: 1.5rem;
        font-family: Roboto;
        font-weight: bold;
        color: #29394d;
        max-width: 75rem;
        display: flex;
        justify-content: space-between;

        .name {

        }
        .score {

        }

        &:not(.fetching) {
          &:first-child {
            background: #18A0FB;
            color: #FFF;
          }
        }
      }

      &.bilingual {
        min-height: calc(100% - 1rem);
        margin: 0 1rem;
      }
    }
  }
}

.quiz-introduction {
  min-height: 100%;
}

.quiz-bilingual {
  &.left-side {
    margin: 3rem -5rem 3rem 0;
    position: relative;
    flex: 0 0 45%;
    max-width: 45%;
    position: relative;
    z-index: 1;
  }

  &.right-side {
    padding-left: 0;
    flex: 1;
    position: relative;
  }

  .quiz-introduction {
    padding-left: 5rem;
  }

  .quiz-introduction, .quiz-language-setter {
    background: #FFF;
    min-height: calc(100% - 2rem);
    margin: 1rem 0;
  }

  .quiz-language-setter {
    border-radius: 20px;
    padding: 2rem;
    font-family: Roboto;
    font-size: 1rem;
    background: #18A0FB;
    color: #FFF;

    h1 { 
      font-family: Roboto;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: .02em;
      margin: 1rem 0 1rem;
      text-transform: uppercase;
      border-bottom: 1px solid #2494e1;
      padding-bottom: 1rem;
      text-align: center;
    }
    h2 { 
      font-size: 1.2rem;
      font-family: Roboto;
      margin-top: 3rem;
      text-transform: uppercase;

      span {
        &:first-child {
          font-size: 1rem;
          margin-right: .5rem;
        }

        i::before {
          content: "\e606";
        }
      }
    }

    .mode-language {
      margin-top: 3rem;
      text-align: center;
    }

    .language-selector {
      margin-top: 2.5rem;
      display: flex;
      justify-content: space-between;

      & > * {
        display: flex;

        span {
          &:first-child {
            line-height: 1.5;
          }
        }
      }
    }
  }

  .progression-info {
    width: 100%;
    background: #f1f2f4;
    margin: 0 1rem;
    border-radius: 4px;
    padding: 1.5rem 3rem;
  }
}

.fs-wrapper {
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  color: #2a3a4c;
  cursor: pointer;

  &:hover {
    background: #fafbfb;
  }
}

.fs-previw-config {
  position: absolute;
  top: 20px;
  right: 20px;
}
