@import "global-propierties";

.survey-builder {
  display: flex;
  box-sizing: border-box;
  flex: 1 1 auto;
  height: 100vh;
}
.row-100 {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
}
.row-100:before, .row-100:after {
  content: " ";
  display: table;
}
.row-100:after {
  clear: both;
}

.transparent-input{
  box-shadow: none !important;
  width: 100%;
  border: none !important;
  background: transparent !important;
  overflow: hidden !important;
  outline: none !important;
  resize: none !important;
  font-weight: normal;
}

.survey-font {
  color:  #8d8d8d;

  &__body {
    font-size: $font-size-body;
    line-height: $line-height-body;
  }
  &--upperCase {
    text-transform: uppercase;
    letter-spacing: .1em;
  }
  &--center{
    text-align: center;
  }
  &--normal{
    text-align: left;
  }
  &__title {
    line-height: $line-height-h1;
    font-size: $font-size-h1;
  }
  &__uppercase {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 32px;
  }
  &__badge {
    font-size: $font-size-badge;
    color : $color-error;
  }

  &__icon-prv {
    line-height: $line-height-icon;
    font-size: $icon-font-size-medium;
    text-align: center;
  }

  &__header {
    line-height: $line-height-h1;
    font-size: $font-size-h1;
    font-weight: 400;
  }
  &__top-bar {
    font-weight: bold;
    text-transform: uppercase;
    font-size: $font-size-h1;
    color: #020c2f;
  }
}

.danger-color {
  color: $color-error;
}

.alert-color{
  color: $color-alert !important;
}
