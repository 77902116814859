.subscribe {
  &__header{
    font-size: 80px;
    text-align: center;
  }
  &__title{
    padding-top: 1rem;
    text-align: center;
  }
  &__content{

    // todo refactoring
    .choices-column {
      text-align: center;
      justify-content: center;
      display: flex;

      .label-choice{
        margin-top: 0;
        margin-left: 10px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}