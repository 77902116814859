.list {
  span{
    font-family: Roboto;
  };
  &-item {
    padding: 0;
    border-radius: 4px;
    border-left: 6px solid;
    margin-bottom: 1rem;
    align-items: stretch;
    cursor: pointer;
    background: #FAFBFB;
    &__count {
      text-align: center;
      span {
        text-transform: capitalize;
      }
      img {
        margin-right: .3rem;
      }
    }
    &__type {
      font-size: 14px;
      color: $fiord;
      span {
        text-transform: capitalize;
      }
    }
    &__header {
      font-size: 1rem;
      font-family: $main-body-font;
      color: $pickled-bluewood;
      font-weight: 500;
      margin-bottom: .5rem;
      overflow: hidden;
      line-height: 19px;
    }
    &__sub-header {
      font-size: 14px;
      color: $fiord;
      .icon-left {
        font-size: .7rem;
      }
    }
    &__contacts {
      font-size: 16px;
      line-height: 19px;
      color: $fiord;
    }
    &__tag{
      padding: .2rem .5rem;
      text-transform: uppercase;
      border-radius: 3rem;
      float: right;
      background: #FFFFFF;
    }
    &__status {
      padding: .2rem .5rem;
      text-transform: uppercase;
      border-radius: 3rem;
      float: right;
      font-size: .7rem;
      background: #FFFFFF;
      i {
        display: inline-block;
        font-size: .5rem;
        width: .6rem;
        height: .6rem;
        border-radius: 50%;
        margin-right: .3rem;
        animation-name: clignote;
        animation-iteration-count: infinite;
        animation-duration: 1s;
      }
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      transition: all .2s linear;
      opacity: 0;
      transform: translateY(-100%);
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        vertical-align: middle;
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
        border-radius: 50%;
        margin-left: -8px;
        cursor: pointer;
        background: #FFFFFF;
        color: #18A0FB;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        &:hover {
          background: linear-gradient(180deg, #18A0FB 0%, #2495E1 100%);
          bottom: .5rem;
          z-index: 1;
          i {
            color: #FFFFFF;
          }
          svg, rect {
            fill: #FFFFFF;
          }
        }

        & > a {
          display: flex;
          flex: 1;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: #18A0FB;
      }
      rect {
        fill: #FFB06A;
      }
    }

    position: relative;

    & > div {
      position: relative;
      padding: 1rem .5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:not(:last-child)::after {
        content: " ";
        position: absolute;
        right: 0;
        width: 0;
        height: 80%;
        margin: auto 0;
        border-left: 1px solid #F1F1F1;
      }
    }

    img.icon {
      margin-right: 4px;
    }

    &:hover {
      background: #F1F2F4;
      z-index: 1;
      .list-item__status {
        display: none;
      }
      .list-item__actions {
        opacity: 1;
        transform: translateY(0%);
        &>div{
          overflow: visible;
          box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
          border: .5px solid #C7E8FE;
        }
      }

      .list__row-groups-cell-wrapper {
        .list__row-group-cell-tag {
          background: #F8F9FA;
        }
      }

      & > div::after {
        border-left-color: #FAFBFB;
      }
    }
  }

  &__row-action-cell-button {
    padding-right: 1rem;
    &:hover{
      cursor: pointer;
    }
  }
  &__row-status-cell-icon {
    font-size: 1.4rem;
    padding: .4rem;
  }

  &__row-group-cell-tag {
    padding: 2px 8px;
    display: inline-block;
    margin: .1rem;
    background: #F1F2F4;
    border-radius: 2px;
    font-weight: 300;
    line-height: 14px;
    color: #3C4E64;
  }

  &__row-groups-cell-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    .hide {
      display: none !important;
    }
    .groups-block {
      padding-right: 2rem;
    }
    .groups-block-position {
      padding-left: 1rem;
      top: -5px;
    }
    .more-groups-container {
      position: absolute;
      line-height: 2rem;
      color: #aaa;
      cursor: pointer;
      right: 2rem;
      text-transform: lowercase;
      font-size: .7rem;
      bottom: -.2rem;
      &:hover {
        color: #777;
      }

      img {
        transition: all .3s ease-in-out;
        &.flip {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  &__sort-button {
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  &__datatable{
    .ttp-datatable__header{
      padding: 15px 5px;
    }
    .ttp-datatable__row{
      padding: 15px 5px;
      cursor: pointer;
    }
  }
}
.duplicate-options .checkbox label{
  letter-spacing: 1px;
  font-size: .8rem;
  text-transform: uppercase;
}


// customize for survey list
.list-item__actions {
  .ttp-drop-down-menu {
    width: 100%;
    height: 100%;
  }
}
