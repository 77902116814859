.move_wrapper {
  justify-content: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    outline: none;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3rem;
    cursor: pointer;
    transition: all 0.1s ease;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 50%;

    * {
      fill: #3f3f3f;
    }
    &:hover {
      background: #18a0fb;
      background: #3f3f3f;

      * {
        fill: #fff;
      }
    }
  }
}

.flip {
  transform: rotateZ(180deg);
}

.age_setter_button {
  margin-left: .5rem;
  min-width: 40px;
}

.age_setter {
  position: absolute;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #f1f2f4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  padding: 0.5rem 1rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  input {
    background: #f1f2f4;
    border: 0.5px solid rgba(109, 127, 146, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    margin-bottom: 0;
    width: 70px;
    height: 28px;
    box-shadow: none;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }

  button {
    outline: none;
    background: #f7953e;
    border-radius: 4px;
    padding: 0.4rem 1rem;
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: #EF8318;
    }
  }
}
