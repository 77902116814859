@import "global-propierties";
$qst-wrapper-margin-right: 66px;

.preview__container {
  margin: 65px;
  margin-right: 70px;

  &__block {
    margin-top: 8px;
  }

  &__title {
    margin-top: 1rem;

    &--image {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      img {
        max-width: 100%;
        max-height: 500px;
        height: 110px;
      }
    }

    &--text {
      width: 100%;
      margin-top: 1rem;
      font-family: $main-body-font;
      font-size: 24px;
      line-height: 28px;
      color: $pickled-bluewood;

      & + h1 {
        margin-top: 2rem;
        padding-top: 2rem;
        box-shadow: 0 -1px #17a0fb;
      }
    }
  }

  &__preview-content {
    padding-left: 50px;
    box-sizing: border-box;
  }

  &--inView &__preview-content {
    opacity: 0.3;
  }

  &__bg {
    position: absolute;
    background: white;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1
  }

  .current-indicator {
    position: absolute;
    transition: visibility 0s, opacity 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    left: 20px;
    top: 50%;
    font-size: 30pt;
  }

  &.subscribe {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 2px;
    transform: scale(1.1);
  }
}

.qst {
  &__required {
    font-size: 35px;
    color: #990000;
    margin-left: 1rem;
  }

  &__wrapper {
    background: #FFF;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(41,57,77,.1);
    border-radius: 4px;
    position: relative;
    transition: all .3s ease-out;
  }

  &__content {
    padding: $spacing-xl $spacing-xxl;
  }

  &__header {
    padding-top: $spacing-m;
    position: relative;

    &__index {
      position: absolute;
      height: calc(100% - 1.5rem); // 1.5 padding of qst__header
      width: 60px;
      background: red;
      right: 100%;
      background: $curious-blue;
      border-radius: 4px 0px 0px 4px;
      font-family: Roboto;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: $alice-pass;
    }

    &__text {
      font-family: Roboto;
      font-weight: 500;
      font-size: 20px;
      color: $alice-pass;
      padding: $spacing-xs $spacing-m;
      padding-left: $spacing-xxl;
      background: $alice-pass;
      display: flex;
      align-items: center
    }
  }
  &__navigation {
    position: absolute;
    right: 0;
    margin-right: -$qst-wrapper-margin-right;
    bottom: calc(50% - 47px); // 47 is the height of qst__navigation / 2

    .mobile-only {
      display: none;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
      border-radius: 4px;
      height: 40px;
      transition: width .3s ease-in-out;
      background: $dodger-blue;
      color: #FFF;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      padding: 0 1rem;
    }
  }
}

.dim {
  &__title__wrapper {
    margin: 8rem 4px 8rem 5px;
    position: relative;
  }

  &__wrapper {
    position: relative;
    margin-top: 8rem;
    margin-bottom: 8rem;
    transition: all .3s ease-out;
  }

  &__header {
    background: $curious-blue;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $alice-pass;
    padding: 1rem 2rem;
  }

  &__label {
    position: absolute;
    left: -60px;
    bottom: calc(100% - 1.5rem);
  }

  &__description {
    background: #FFFFFF;
    border: 1px solid $alice-pass;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 4px;

    &__content {
      padding: 3rem 4rem;
      padding-bottom: 1.5rem;
    }

    .content {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: $fiord;
    }
  }
}

.qst-wrapper {
  margin-top: 65px;
  margin-bottom: 65px;
  padding: 0;
  background: yellow;
}

@media only screen and (max-width: 768px) {

  .preview__container {
    margin: 4rem 0;
    margin-bottom: 8rem;

    &__preview-header {
      align-items: center;
      display: flex;
      .survey-font__top-bar {
        display: none;
      }
    }
  }

  .qst {
    &__header {
      padding-top: 0;
  
      &__text {
        padding-left: 2rem;
        padding-right: 2rem
      }
    }

    &__content {
      padding: 2rem;
    }

    &__header__index {
      border-radius: 4px 4px 0px 0px;
      height: 50px;
      left: 0;
      bottom: 100%;
    }

    &__navigation {
      margin: 0;
      margin-right: 2rem;
      margin-bottom: -20px;
      bottom: 0;
  
      .navigation-button {
        display: none;
      }
  
      .mobile-only {
        display: block;
      }
    }
  }

  .dim {
    &__label {
      left: 0;
      bottom: 100%;
    }
  }
}