.viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding-right: 0rem;
}

.media_container {
  position: relative;
  //background: linear-gradient(45deg, rgba(71, 201, 229, 0.45) 0, rgba(80, 129, 190, 0.53) 100%);
  background-color: #fff;
}
.data_viewer {
  padding: 2rem 2rem !important;
  background-color: #fff;

  .data_content {
    padding-right: 0rem;
    min-height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      box-shadow: none;
      border: none;
    }
  }
}
