@import "global-propierties";

.ranking-list{
  width: 100%;

  &__sort{
    &__icon{
      margin-left: 10px;
      color: $main-survey-blue;

      &:hover{
        cursor: pointer;
      }
    }
  }

  table {
    td, th {
      border-right: 1px solid #f1f1f1;
    }
    th > label {
      font-weight: bold;
      background: white;
      display: inline-block;
      border-radius: 30px;
      padding: 0 1rem;
    }
  }

  .cell-rank {
    background: #f8f8f8;
    color: #0a0a0a;
    font-size: 1.2rem;
    font-weight: bold;
  }

  & > * {
    width: 100%;
  }
}

.filter-wrapper {
  display: flex;
  position: relative;
  // margin-left: 10px;
  .filter-button {
    margin-bottom: 5px;
    border-radius: 3px;

    .icon{
      font-size: .6rem;
      font-weight: 700;
      margin-left: .4rem;
    }
  }

  .filter-form {
    margin-top: 50px;
    left: 0;
    padding: 7px;
    border: 1px solid #CCC;
    background-color: #FFF;
    min-width: 300px;
    z-index: 10;
    position: absolute;

    .button {
      margin-bottom: 0px;
      padding: 7px;
      margin-right: 10px;
    }
  }
}

