.DateRangePicker {
  transform: translateX(-.6rem);
  padding-left: .6rem;
  width: calc(100% + .6rem);

  text-transform: uppercase;
  color: #6D7F92;

  .DateRangePickerInput {
    border: none;
    background-color: inherit;

    .DateInput {
      width: 45%;

      .DateInput_input {
        margin: 0;
        width: 100%;
        padding: 11px 11px 9px;
        box-shadow: none;
        border-radius: 4px;
        border: 1px solid hsl(0,0%,80%);
        height: 38px;
        &__focused {
          outline: 0;
          background: #fff;
          border: 2px solid #2684FF;
        }
        &::placeholder {
          color: $slate-gray;
          text-transform: uppercase;
          font-size: .8rem;
        }
      }
    }
  }

  .CalendarMonth_caption {
    // padding-top: 30px;
  }

  .DateRangePickerInput_arrow_svg {
    fill: $slate-gray;
    height: 15px;
  }

  .DateRangePickerInput_clearDates {
    margin: 0;

  }

  .dark {
    .DateRangePickerInput_clearDates {
      background-color: #FAFBFB;
    }
  }

  .CalendarDay {
    padding: 0;
  }
  .CalendarDay__selected_span {
    background: #1779bace;
  }
  
  .CalendarDay__selected {
    background: #14679e;
  }
  
  .CalendarDay__selected:hover {
    background: #1779ba;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected,
  .CalendarDay__selected:hover {
    border: 1px solid #14679e;
    color: white;
  }
  
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #1779babe;
    border: none;
    color: white;
  }
}