.article-file {
  padding: 0 !important;
  background-color: #fff;

  .article-meta {
    padding-left: 1rem;
  }
  .article-content {
    padding-right: 2rem;
    min-height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      box-shadow: none;
      border: none;
    }
  }

  .slick-dots {
    bottom: 4px;

    li button {
      border: 1px solid #fff;
      opacity: 0.8;
    }
  }

  &__stats-section {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    &-title {
      font-size: 2.6rem;
      padding-top: 1rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: nth($main-blue-alpha, 8);
      z-index: 0;
    }
  }

  &__stats {
    //padding-top: 1.5rem;
    //padding-bottom: 1.5rem;
    padding: 1rem 1rem 3.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &-mask {
      height:100%;
      width:100%;
    }
  }

  &__stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    z-index: 1;
    border-right: 1px solid $main-blue;

    &:last-child {
      border-right: 0;
    }

    &-device {
      &-icon {
        font-size: 3rem;
      }

      &-percentage {
        font-size: 1.4rem;
        font-weight: bold;
        color: $main-orange;
      }
    }

    &-value {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $main-orange;
      font-size: 2.3rem;
      height: 3rem;
      font-family: $h-font-family;
      font-weight: bold;

      &.small-text{
          font-size: 1.8rem;
      }
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      //flex-direction: column;
      text-align: center;
      font-size: 1.1rem;
      height:2rem;
    }
  }

  &__stats-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    //width: 100%;
    // border-bottom: $light-border;
    padding: 2rem 0;
    margin-left: auto;
  }

  &__stats-btn {
    background-color: #f0f0f0;
    border-radius: 3rem;
    //margin-right: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    //padding: 0 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    height: 3rem;
    min-width: 3rem;
    font-weight: bold;
    font-size: rem-calc(13px);
    .icon {
      font-size: 1.2rem;
    }
    transition: nth($transitions, 3);
    &.activated, &:hover {
      color: #FFF;
      background-color: $main-blue;
    }
  }

  ul {
    margin: 0;
  }
  &__title {
    padding: 2rem 0 1.2rem 0;
    border-bottom: $light-border;
    width: 100%;
  }
  h1, h2, h3, h5 {
    font-family: $h-font-family;
    font-weight: bold;
  }
  &__edit, &__delete, &__print {
    color: $main-color;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    min-height: 15rem;
    position: relative;
    width: 100%;
    &.video {
      img {
        transform: scale(1);
      }
      span {
        position: absolute;
        width: 5rem;
        cursor: pointer;
        z-index: 1;
      }
    }
    &.pdf, &.ppt {
      border: 1px solid #ccc;
      padding: .5rem;
      cursor: pointer;
    }
    &.ppt span, &.pdf span {
      position: absolute;
      width: 3rem;
      top: 1rem;
      right: 1rem;
    }

    @include breakpoint(small only) {
      min-height: auto;
    }
  }
  h1 {
    color: $article-title-link-color;
    font-size: 2rem;
    margin-bottom: .7rem;
  }
  &__meta-data {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    flex-wrap: wrap-reverse;
  }
  &__date {
    margin-right: 1rem;
  }
  &__category, &__community {
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(103, 102, 102);
    .icon {
      margin-right: .5rem;
    }
  }
  &__category {
    margin-right: 1rem;
  }
  &__content {
    padding: 2rem 0 3rem 0;
    color: $survey-color;
    width: 100%;
    a {
      color: $survey-blue;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  &__tags {
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: $light-border;
    clear: both;
    ul {
      display: flex;
      font-family: $h-font-family;
      flex-wrap: wrap;
      li {
        background-color: #f0f0f0;
        margin: 0 1rem 1rem 0;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.8rem;
        & > * {
          color: $main-color;
          padding: .5rem 1rem;
          display: inline-block;
        }
        &:hover {
          a {
            background-color: $main-blue;
            color: #FFF;
          }
        }
      }
    }
    h3 {
      font-size: 1.2rem;
      color: $article-title-link-color;
      margin-bottom: 1.3rem;
    }
  }
  &__attachments {
    padding: 1rem 0;

    li .icon {
      margin-right: 0.5rem;
    }

    .pdfs-list {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 4rem;
      }
    }

  }
  &__social {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    margin-bottom: 2rem;
    &.top-part {
      margin-bottom: 0;
      position: relative;
      // border-bottom: $light-border;
    }
  }
  &__share, &__edit, &__delete, &__print {
    background-color: #f0f0f0;
    border-radius: 3rem;
    margin-right: 1.5rem;
    //margin-bottom: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    height: 3rem;
    font-weight: bold;
    font-size: rem-calc(13px);
    .icon {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
    transition: nth($transitions, 3);
    &.activated, &:hover {
      color: #FFF;
      background-color: $main-blue;
    }
  }
  &__share {
    margin-right: .5rem;
  }
  &__actions{
    display: flex;
    align-items: flex-start;
    border-bottom: $light-border;
  }

  &__shared-in-workflow {
    position: absolute;
    top: 4.25rem;
    font-size: .45rem;
    right: 2.4rem;
    height: 1rem;
    color: rgb(255, 170, 0);
  }
  &__networks {
    display: flex;
    li {
      background-color: #f0f0f0;
      border-radius: 50%;
      margin-right: .5rem;
      font-size: 1.2rem;
      width: 3rem;
      height: 3rem;
      /*display: flex;
      align-items: center;
      justify-content: center;*/
      &.ttp {
        background-color: #fff;
        cursor: pointer;
      }
      .article-rs {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          color: $main-blue;
        }
      }
    }
  }
  .ttp-share {
    position: relative;
    top: 0.1rem;
  }
  &__hide {
    display: none;
    background: nth($main-blue-alpha, 4);
    padding: 0.5rem 0 .5rem .5rem;
    position: relative;
    top: 1rem;
    left: 26rem;
    border-radius: 3rem;

    @include breakpoint(small only) {
      flex-basis: 100%;
    }
  }

  .latest-articles{
    h1{
      margin: 2rem 0;
    }

    @include breakpoint(small only) {
      .columns.small-12 {
        padding: 0;
      }
    }
  }
  .public-DraftEditor-content {
    img {
      margin: .2rem .4rem;
    }
  }

  .article-pdf-preview {
    position: relative;
    max-width: rem-calc(300px);
    height: rem-calc(350px);
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 1rem 0;
    border-radius: 4px;
    box-shadow: 0px 0px 45px -9px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transition: nth($transitions, 3);

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.06);
      top: 2px;
      transition: nth($transitions, 3);
    }

    &--loading {
      align-items: center;
      background: rgba(0,0,0,0.7);
      width: 18.75rem;
    }

  }
  .comment-article {
    padding-top: 0;
    background: $survey-color;
    border-radius: 5px;
    //background-image: linear-gradient(to bottom right, $survey-color, $icon-color);
    & > div:first-child {
      border-radius: 5px;
      overflow: hidden;
    }
    //border: 2px solid $survey-color;
    border-top: 0;
    height: auto;
    
  }
  .social-article {
    top: 0;
    background-color: $survey-color;
  }
  .comment-list-article {
    min-height: 15rem;
    height: auto;
    background-color: $survey-color;
  }
}