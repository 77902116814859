@keyframes pos {
  0% {
    top: 0;
  }

  100% {
    top: -80%;
  }
}

.emtp {
  .header-wrapper {
    padding-top: 1rem;
    margin-bottom: 2rem;

    &::after {
      content: "";
      padding-top: .7rem;
      border-bottom: 1px solid #e9ebeecc;
      display: block;
    }
  }
  .types-wrapper{
    padding-top: 1rem;
  }
  .type {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    background: #e9ebee;
    font-weight: 700;
    border-bottom: 5px solid transparent;
    cursor: pointer;

    &.active {
      border-bottom: 5px solid #5080be;
      background: #dbdfe4;
      color: #2c2b3f;
    }

    &:hover {
      background: #dbdfe4;
      color: #2c2b3f;
    }
  }

  .tpreview {
    height: 300px;
    text-align: center;
    padding-top: 8px;
    // overflow: hidden;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.04);
      z-index: 1;
    }

    img {
      width: 100%;
      position: relative;
    }

    &.new{
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 20px;
      border: 2px dashed #5081be;
      border-radius: 4px;
      min-height: 353px;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    // height: 235px;
    padding: 0;
    overflow: visible !important;
    cursor: pointer;
    animation: fadeIn 0.1s ease-in-out;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 2px 12px rgba(0, 0, 0, .06), 0 8px 14px rgba(0, 0, 0, .04), 0 12px 16px rgba(0, 0, 0, .02);
      transition: box-shadow .3s ease-in-out;

      .emtp__card__actions {
        opacity: 1;
        z-index: 3;
        transform: translate(-50%,-50%);
      }

      .tpreview {
        &::before {
          background: rgba(0, 0, 0, 0.4);
        }
      }

      .tpreview img {
        animation: pos 5s ease-out;
        animation-fill-mode: forwards;
      }
    }

    &__actions {
      position: absolute;
      top: 9rem;
      transform: translate(-50%,-150%);
      left: 50%;
      width: 95%;
      opacity: 0;
      transition: opacity .2s,transform .3s;

      .styled-button {
        width: 49%;
        margin: 0 0 1rem;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__container {
      flex: 1;
      margin: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      vertical-align: middle;
      position: relative;

      overflow: hidden;
    }

    &__info {
      display: flex;
      padding: 5px;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px 0px 2px 2px;

      .language {
        font-size: 1.3rem;
        img {
          width: 35px;
        }
      }
    }

    &__label {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: center;
      background-color: #f2f2f2;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      width: 50px;
      height: 28px;
      color: #747474;
    }

    &__title {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      z-index: 1;

      h5 {
        font-family: "Roboto","Helvetica","Arial",sans-serif;;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        text-align: center;
        margin: 8px 5px;
      }

      &--fetching{
        padding: 5px;
      }
    }

    &__title-type {
      color: #000000;
      @extend %title-shared;
    }

    &__title-fetching {
      color: #FFFF;
      text-align: left;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  .add-new {
    display: flex;
    & > .emtp__item {
      flex: 1;
      & > .tpreview {
        height: 100%;
      }
    }
  }

  .gallery{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;

    &__nav{
      display: flex;
      justify-content: center;
      align-items: center;

      &__item{
        cursor: pointer;
        border-bottom: 3px solid #eee;
        text-transform: uppercase;
        padding: 0.5rem 1.5rem;

        &.active{
          color: #3f3f3f;
          margin-bottom: -1px;
          border-bottom: 4px solid #5080be;
        }
      }
    }

    &__tab {
      margin-top: 2rem;
    }
  }
}
