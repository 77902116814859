@keyframes player {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.quiz-pin {
  padding-top: $spacing-l;

  .game-pin {
    padding: 1rem 2rem;
    margin: 0 2rem;
    border-radius: 4px;
    background: #FFF;
    position: relative;

    h1 {
      font-size: 2rem;
      font-weight: 500;
    }

    h1, h4 {
      font-family: Roboto;
      color: $pickled-bluewood;
    }

    .pin {
      display: inline-block;
      background: #C7E8FE;
      border-radius: 4px;
      padding: 0 1rem;
      font: 700 5.5rem Roboto;
    }
  }

  &__actions {
    // padding: 1rem 3rem;
    margin: 1rem 0;
    padding: 1rem 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background: #FFF;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    .total-players {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: Roboto;
      align-items: center;
      color: $pickled-bluewood;

      .total {
        font-size: 3rem;
        line-height: 1.2;
      }

      .text-label {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1;
        color: $curious-blue;
      }
    }

    .action {
      align-items: center;
      display: flex;
    }
  }

  &__players {
    padding: 1rem 2rem;
    margin: 0 2rem;
    // padding: 1rem 3rem;
    padding-top: 2rem;
    background: #fff;
    display: flex;
    color: $fiord;
    margin-bottom: 3rem;
    align-items: stretch;
    min-height: 25rem;
    align-content: flex-start;

    .player {
      padding: 0 1rem;
      margin-bottom: 1.5rem;

      .container {
        background: #c7e8fe;
        background: #F1F2F4;
        padding: 1rem;
        text-align: center;
        border-radius: 4px;
        height: 100%;

        .name {
          font-family: Roboto;
          font-size: 1.2rem;
          animation: 1s ease 0s 1 normal none running player;
        }
      }
    }
  }
}

.quiz-pin-page {
  h1 {
    font-family: Roboto;
    color: #29394D;
    font-size: 8rem;
    font-weight: 700;
    background: #C7E8FE;
    border-radius: 10px;
    padding: 1rem 3rem;
  }
}