.question-choice {
  padding-left: 55px + 8px; //TODO same as textarea
  position: relative;

  &__container {
    display: flex;
    align-items: flex-start;

    &__badge{
      margin-right: 7px;
      font-weight: 900;
    }
    &__content{
      flex: 1;
      .text {
        display: flex;
        flex: 1;
      } 
      .counter {
        color: #cae085;
        font-family: Roboto;
        font-weight: 700;
        margin-left: .5rem;
        min-width: 40px;
      }
      .choice_settings {
        margin: 0 .5rem;
        cursor: pointer;

        &:hover {
          color: #3C4E64;
        }
      }
    }
  }

  &__icon-big {
    font-size: $icon-font-size-big;
  }

  &__helper{
    margin-left: 15px;
  }
  textarea {
    //height: 25px;
    min-height: 30px;
    padding: 2px;
    margin-bottom: 0px;
  }

  .choice_settings {
    position: absolute;
    left: 38px;
    cursor: pointer;
    color: #222528;
    &:hover {
      color: #2b2e33;
    }
  }
}
