.ttp-alert-dialog {
  text-align: center;

  .alert-message {
    font-size: 1.2rem;
    margin: 1.2rem auto;
  }


  .alert-footer {
    display: flex;
    justify-content: space-between;
    .button {
      margin: 1rem 1%;
      width: 40%;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      padding: 1rem;
    }

    .cancel-button {
      background-color: #aaa;
    }

    .ok-button:hover,  .cancel-button:hover{
      border: 1px solid rgba(0, 0, 0, 0.8);
      background-color: #fff;
      color: black;
    }
  }

  .modal-checkbox-group {
    text-transform: uppercase;
    text-align: left;

    .checkbox {
        padding: 5px;
    }
  }
}

//prevent overflow-y on the body while the modal is open
.ReactModal__Body--open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
