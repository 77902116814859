.ReactModal__Overlay {
  z-index: 10;
  background-color: rgba(41, 57, 77, 0.4) !important, /* !important to override modal inline-style  */
}

.ReactModal__Content {
  transform: scale(.1);
}

.ReactModal__Content--after-open {
  transform: scale(1);
  transition: transform .5s ease-in-out;
}

.ReactModal__Content--before-close {
  transform: scale(0.1);
}

.ReactModal__Body--open {
  position: relative; /* To fix bug scroll to top After modal is opened */
}

.rmodal {
  height: 100%;

  // transition: transform .5s ease-in-out;
  // &.reduce {
  //   transform: scale(.1);
  // }
  &__next,
  &__previous,
  &__close {
    cursor: pointer;
    background-color: $reveal-bg;
    border: 1px solid #FFF;
    height: 2.7rem;
    width: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -.2rem;
  }

  &__close {
    right: 2rem;
    z-index: 1;
  }

  &__next {
    right: 2rem + 3rem;

    .next {
      height: 1.2rem;
      width: 1.25rem;
    }
  }

  &__previous {
    right: 2rem + 6rem;

    .previous {
      height: 1.2rem;
      width: 1.25rem;
    }
  }

  &__body {
    //width: 100rem;
    //height: 20rem;
    padding: 2rem;
    background-color: $reveal-bg;
    height: 100%;
    overflow: auto;
    padding-bottom: 0.5rem;
  }
}

@import "./reveal/viewer";
@import "./reveal/emailing-viewer";
