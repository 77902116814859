
.drop-down-option,
.drop-down-option__left {
  display: inline-block;
  margin: 0;
  position: relative;
  
  .icon-options {
    color: #4c4c4c;
    display: flex;
    justify-content: center;
  }

  ul {
    position: absolute;
    margin: 0;
    top: 100%;
    box-shadow: 0 3px 12px 1px rgba(44, 43, 63, .1), 0 0 0 1px rgba(44, 43, 63, .13);
    margin-top: 8px;
    border-radius: .2rem;
    border-top-right-radius: 0;
    display: none;
    transition: nth($transitions, 2);
    padding-right: 1rem;
    background: #fff;
    z-index: 2;
    margin: 0;
    padding: 0;
  }

  .centered-list {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
  }

  li {
    margin: 0;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    color: #000;
    line-height: 2rem;
    transition: nth($transitions, 3);
    position: relative;
    text-align: left;
    top: 1px;
    cursor: pointer;
    white-space: nowrap;

    a {
      color: $color-grey-1;
      white-space: nowrap;
    }

    &:first-letter {
      text-transform: uppercase
    }

    &:hover {
      background-color: rgba(0, 0, 0, .07);
    }

    &:hover, &:focus, &:active {
      ul.nested-menu {
        opacity: 1;
        transition: opacity .3s ease-in-out;
      }
    }

    // Nested menu
    ul.nested-menu {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 100%;
      right: auto;
      z-index: 1;
      display: block;
      min-width: 100px;
      background: #fefefe;
      transition: display .3 ease-in-out;
    }


    &.left, &.right {
      position: relative;

      & > a::after {
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: inset 6px;
        content: '';
      }
    }

    &.left {
      & > a::after {
        right: auto;
        left: 5px;
        top: calc(50% - 6px);
        border-left-width: 0;
        border-right-style: solid;
        border-color: transparent #cbcbcb transparent transparent;
      }

      ul.nested-menu {
        top: 0;
        right: 100%;
        left: auto;
      }
    }

    &.right {
      & > a::after {
        right: 5px;
        left: auto;
        top: calc(50% - 6px);
        border-right-width: 0;
        border-left-style: solid;
        border-color: transparent transparent transparent #cbcbcb;
      }

      ul.nested-menu {
        top: 0;
        right: auto;
        left: 100%;
      }
    }
  }

  &__left {
    top: 30px;
    right: 1rem;
    border-radius: 8px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 2px 12px rgba(0, 0, 0, .06) !important;
    padding: 8px 0 !important;
  }

  &--show {
    right: 0;
    display: block !important;
  }
}

.drop-down-option {
  ul {
    &.expanded {
      top: 35px;
    }

    &.default {
      top: 30px;
    }
  }
}

.no-padding .dropdown.menu.vertical>li>a {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.srv-submenu>.is-submenu-item {
  margin: 16px 32px !important;
}

.is-dropdown-submenu {
  min-width: 260px;

  >li {
    width: inherit;
  }
}