#subMenu,
.sub-menu {
  position: relative;
  background: $pickled-bluewood;

  &:not(.vertical) {
    padding-left: $spacing-xxl;
  }

  .flip-menu {
    cursor: pointer;
    position: absolute;
    top: calc(100% - 30px/2);
    left: 1rem;
    width: 30px;
    height: 30px;
    background: $pickled-bluewood;
    color: #fff;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 1;
    transition: transform .2s ease;

    i {
      margin: auto;
    }

    &:hover {
      transform: rotateZ(-90deg);
    }
  }

  .menu {
    li {
      align-items: center;
      color: $french-pass;
      font-family: $main-body-font;
      font-weight: normal;
      height: 44px;

      .menu__link {
        color: $french-pass;
        display: flex;
        align-items: center;
        height: 100%;

        .title {
          padding-left: $spacing-s;
          text-transform: uppercase;
          overflow: hidden;
          padding: 1rem;
          padding-left: 0.5rem;
          padding-right: 0;
        }

        &:hover, &.active {
          background: $fiord;
        }
      }

      .icomoon, .icon {
        font-size: 1.1rem;
      }

      a {
        padding: 0.8rem 1rem;
      }
    }
  }

  &.vertical {
    padding-top: $spacing-m;
    width: 4rem;
    justify-content: center;
    display: flex;
    flex-shrink: 0;
    transition: all .5s cubic-bezier(0, 0, 0.2, 1);

    .flip-menu {
      visibility: hidden;
    }

    .menu {
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      li {
        margin: $spacing-xxs 0;
        min-height: 2rem;

        .menu__link {
          border-left: 3px solid transparent;

          .title {
            opacity: 0;
            visibility: hidden;
            display: none;
            white-space: nowrap;
            overflow: hidden;
          }

          &:hover, &.active {
            border-left: 3px solid $dodger-blue;
            background: $fiord;
          }
        }

        .icomoon {
          font-size: 1.22rem;
        }
      }
    }

    &:hover {
      width: 14rem;
      justify-content: flex-start;

      .flip-menu {
        visibility: visible;
        left: calc(100% - 30px/2);
        top: .6rem;
        transform: rotateZ(-90deg);

        &:hover {
          transform: rotateZ(-180deg);
        }
      }

      .menu li .menu__link .title {
        opacity: 1;
        visibility: visible;
        display: block;
        transition: all .4s;
      }
    }
  }
}