@import "../../components/global-propierties";
.stats-page {
  background-color: #e9ebee;
  display: flex;
  flex: 1;
  padding: 1rem 0;
  &>.expanded {
    flex: 1;
  }
}

.stats {
  &__menu-item {
    padding: 0.5rem 1rem;
    padding-left: .7rem;
    border-left: 5px transparent solid;
    cursor: pointer;
    color: $menu-font-color;
    display: flex;
    &:hover,
    &.active {
      background: #dbdfe4;
      color: $menu-active-font-color;
      font-weight: bold;
    }
    &.active {
      border-left: 5px $main-survey-blue solid;
    }
    &.fetching {
      background: #dbdfe4;
      margin-bottom: 5px;
    }
    &.child {
      padding-left: 2rem;
      display: block;
      &:hover {
        background: transparent;
      }
      &.active {
        border-left-color: transparent;
        background: transparent;
        color: #5081be;
      }
    }

    .gradiant {
      background: linear-gradient(to right, #e9ebee, #dbdfe4);
    }
  }
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    &> :not(:last-child) {
      margin-bottom: 0.15rem;
      ;
    } // & > div {
    //   padding: 0 1rem;
    // }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #fff;
    padding: 1rem;
    color: #3f3f3f;

    >h4 {
      margin: 0;
      font-weight: 600;
    }

    &__wrapper{
      background: #fff;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: ROBOTO;
      font-weight: normal;
      font-style: italic;
    }
  }
  &__show-all{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    & > :not(:first-child) {
      padding-left: 10px;
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > .wrapper {
      padding-top: 2rem;
    }

    & > * {
      background: #fff;
      width: 100%;

      padding-left: .5rem;
      padding-right: .5rem;
    }

    & > :last-child{
      flex: 1;
    }
  }

  &__not-found {
    padding: 20px;
    border-radius: 4px;
    margin-top: 4rem;

    .icon {
      width: 120px;
      text-align: center;
      padding-bottom: 10px;
      padding-top: 10px;

      img {
        opacity: 0.4;
        max-width: 120px;
        filter: brightness(120%) grayscale(100%);
      }
    }

    .content {
      padding-left: 0;
      padding-top: 20px;
      text-align: center;

      .header {
        font-size: 18px;
        padding-bottom: 5px;
        color: #3F3F3F;
        font-weight: bold;
      }

      .message {
        font-size: 14px;
      }
    }
  }
}

.no-pading-left {
  padding-left: 0;
}