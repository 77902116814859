@import "../global-propierties";

.s-toolbar {
  padding: 10px 0px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #222528;;
  //overflow: hidden;
  box-sizing: border-box;
  overflow-y: auto;

  .separator {
    box-shadow: 0 -1px rgba(0,0,0,.07) inset;
  }

  &__title {
    padding: 10px 20px;
    display: flex;

    &__label {
      font-weight: 700;
    }

    &__icon{
      cursor: pointer;
      margin-left: auto;
      font-size: $icon-font-size-tiny;

      span {
        padding: 5px;
        border-radius: 3px;
        text-align: center;
        align-items: center;
        display: flex;
        color: $color-grey-1;

        &:hover {
          background-color: $color-grey-4;
        } 
      }
    }
  }
  &__button{

    a{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:hover {
        outline: none;
        color : white !important;
        background-color: rgba($main-survey-blue,.7);
      }
    }

    &--raduis {
      border-radius: 2px !important;
      text-transform: uppercase !important;
    }

    &--full-width{
    width: 100%;
    justify-content: center;
    font-weight: 700;

      &:disabled {
        background-color: rgba($main-survey-blue,.4);
      }
    }
  }
  &__items {
    flex: 1;
   // overflow-y: auto;
    padding: 16px 16px 24px;
  }

  &__settings {
    flex: 1;
    overflow-y: auto;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  &__menu {
    display: flex;
    padding: 0 20px;

    &.item {
      cursor: pointer;
      text-align: center;
      color: #8d8d8d;
      font-size: 10px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 0.1em;
      padding: 8px 0;
      text-transform: uppercase;
      transition: 0.4s;

      &:not(:last-child) {
        margin-right: 16px;
      }

      &.selected {
        box-shadow: inset 0 -2px 0 0;
      }
    }
  }

  &__emtmp{
    padding: 0;
    background: #fff;
    iframe {
      width: 100%;
      height: 100%;
      box-shadow: none;
      border: none;
    }
  }

  .DateRangePicker .DateRangePickerInput {
    .DateInput {
      background: none;

      svg.DateInput_fang {
        top: 56px !important;
      }
    }

    .DateRangePicker_picker {
      background-color: transparent;
    }
  }
}
.hide {
  display: none !important;
}



