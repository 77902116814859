@import "../global-propierties";


.srv-scale{
  &__wrapper{
    width: 100%;
    height: 64px;
    color: $main-survey-blue;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    // To delete after using themes
    > .srv-scale__elm {
      background: #FAFBFB;
      border: 1px solid $athens-gray;
      box-sizing: border-box;
      border-radius: 4px;
      color: $pickled-bluewood;

      &:hover {
        background: $alice-pass;
        border-color: transparent;
      }

      &.is-selected {
        background: $curious-blue;
      }
    }
  }

  &__elm{
    font-size: $font-size-h3;
    line-height: 28px;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: .18rem;
    }

    is-selected {
      color: white;
    }
  }
}
