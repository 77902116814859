@import "../global-propierties";

.btn-ctrl {
  margin-top: 10px;
  display: flex;
  align-items: center;
  outline: none;

  &--center{
    justify-content: center;
  }
  &--submit {
    // margin-bottom: 200px !important;
  }
  &__btn {
    outline: none;
    border-radius: 4px;
    display: flex;
    color: #fff;
    padding: 10px 16px;
    background-color: $main-survey-blue;
    cursor: pointer;
    align-items: center;

    &:hover:enabled {
      background-color: rgba($main-survey-blue,.7);
    }

    &:disabled {
      background-color: rgba($main-survey-blue,.5);
    }
  }

  &__pbtn {
    outline: none;
    border-radius: 4px;
    display: flex;
    border: 1px solid;
    color: #fff;
    padding: 10px 16px;
    background-color: $main-survey-preview-color;
    cursor: pointer;
    align-items: center;

    // &:hover:enabled {
    //   background-color: rgba($main-survey-preview-color,.7);
    // }

    // &:disabled {
    //   background-color: rgba($main-survey-preview-color,.5);
    // }
  }

  &__block-btn{
    margin:0;
    min-width: 40px;
  }

  &--small {
    padding: 6px 10px;
    height: 40px;
    border-radius: 30px;
  }
  &--black {
    background: #262627;

    &:hover:enabled {
      background: rgba(#000000, .7)
    }
  }
  &--big {
    padding: 15px;
  }
  &--radius{
    border-radius: 30px;
  }
  &--fullwidth {
    width: 100%;
    justify-content: center;
    font-weight: 700;
  }
  &--large {
    padding: 10px 80px 10px 80px;
    border-radius: 30px;
  }
  &--meduim-large {
    padding: 10px 40px 10px 40px;
    border-radius: 30px;
  }
  &__icon{
    font-size: 15pt;
  }

  &__btn-label{
    font-size: 18px;
    font-weight: 700;
    margin: 0 5px;
    line-height: 25px;

    &--big {
      font-size: 25px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
      line-height: 1;
    }
    &--meduim {
      font-size: 20px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
      line-height: 1;
    }
  }

  &__btn-helpText {
    color: $main-survey-blue;
    &--pcolor{
      color: $main-survey-preview-color;
    }
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    margin-left: 10px;
    display: flex;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }
}

.navigation-button {
  display: flex;
  &.vertical {
    flex-direction: column;
    align-items: flex-end;

    button {
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }

  &:not(.vertical) {
    button {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

  button {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    width: 44px; // = $qst-wrapper-margin-right x 2
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width .3s ease-in-out;
    color: $dodger-blue;
    cursor: pointer;
    outline: none;

    .text {
      width: 0;
      height: 0;
      visibility: hidden;
      width: 100px;
      height: 44px;
      font-size: 16px;
      font-family: Roboto;
      position: absolute;
      right: 100%;
      transform: translateX(50px);
      transition: transform .2s ease-in;
      align-items: flex-start;
    }

    &:hover, &.show {
      width: 150px;
      background: $dodger-blue;
      color: #FFF;

      .text {
        z-index: 2;
        visibility: visible;
        right: 40px;
        padding-left: .5rem;
        transform: translateX(0);
        transition: transform .2s ease-in, visibility .5s ease-in;
      }
      .icon-wrapper {
        color: #FFF;
        width: 44px;
        height: 44px;
        position: absolute;
        right: 0;
        background-color: transparent;
      }
    }

    .icon-wrapper {
      z-index: 3;
      color: $dodger-blue;
      background-color: white;
      border-radius: 4px;
    }
  }
}