.no-data-page {
  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    margin: 2rem 0rem;
    align-items: center;
    flex-direction: column;
  }

  .icon {
    font-size: 120pt;
    line-height: 1;
    opacity: 0.5;
  }

  .msg {
    font-weight: bold;
    color: #2c2b3f;
    font-size: 30px;
    line-height: 1.5;
  }
}
