@import "../global-propierties";

.settings {
  &__block {
    font-size: 14px;
    line-height: 30px;
    box-shadow: 0 -1px rgba(0,0,0,.07) inset;
    padding: 10px 15px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__label{
      padding-right: 10px;
      font-size: 14px;
      line-height: 30px;
    }
    &__item{
      margin-left: 10px;

      .steps-list {
        margin: 0;
        ul {
          display: flex;
          color: #fff;
          align-items: center;
          justify-content: center;
          margin: 0;
          font-weight: bold;
          height: 2rem;
          li {
            background: #ccc;
            padding: .1rem .8rem;
            margin: .1rem;
            cursor: pointer;
            transition: 0.2s font-size ease-in-out;

            &.selected {
              background-color: $main-blue;
            }
            &:not(.selected):hover {
              background-color: #89afdf;
            }
          }
        }
      }
    }
  }

  &__input {
    margin : 10px 0px;
    border-radius: 5px;
  }

  //&__input{
  //  width: 100%;
  //  border : 1px solid rgba($color,.5);
  //  outline: none;
  //  padding: 5px 11px;
  //  border-radius: 5px;
  //  margin : 10px 0;
  //  max-height : 33px;
  //  font-size: $font-size-body;
  //  line-height: $line-height-body;
  //
  //  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
  //    -webkit-appearance: none;
  //    -moz-appearance: none;
  //    appearance: none;
  //    margin: 0;
  //  }
  //}
  &__switch {
    padding-left : 18px;
    margin: 0;

    &.disabled {
      opacity: .5;
      & > .switch-paddle {
        cursor: not-allowed;
      }
    }
  }

  &__slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  &__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $main-survey-blue;
    cursor: pointer;
  }

  &__slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $main-survey-blue;
    cursor: pointer;
  }

  .btn {
    margin-left: 6px;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 5px 11px;
    min-height: 32px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 3px;
    margin-bottom: 0;
    font-weight: 500;
    color: #212427;
    background-color: #d8d8d8;
    outline: none;

    &.disabled{
      cursor: not-allowed;
    }

    &:hover{
      opacity: .9;
    }
  }
}
