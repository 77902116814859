@import "../global-propierties";

$nb-response-bg: #F8F8F8;
$nb-response-color: #747474;

@keyframes animate-list {
  0% {
    opacity: 0;
    transform: translateY(-60px);
    height: 0px;
  }

  50% {
    height: 100px;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
    height: 100%;
  }
}

.summary {
  &__wrapper {
    margin-bottom: 0.15rem;
  }

  &__block {
    width: 100%;
  }

  &__header {
    display: flex;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .text {
      padding: 15px 45px;
      margin-right: 2px;
      align-items: center;
      text-align: center;
      justify-content: center;
      display: flex;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .number {
      padding: 15px 45px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &>* {
      background: $nb-response-bg;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__dimension {
    display: flex;
    border-radius: 4px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;

    .title {
      background: #F2F7FC;
      border-radius: 4px;
      color: #3F3F3F;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  &__qst {
    font-size: 12px;
    font-weight: 500;
    font-family: Roboto, 'Segoe UI', Helvetica, sans-serif;
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding-top: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;

    &.with-others {
      justify-content: space-between;
      display: flex;
      width: 100%;
      flex-direction: column;

      &>.other.header {
        max-height: 35px;
      }
    }

    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      color: #3F3F3F;
    }

    .responses {
      margin-bottom: 20px;

      &::before {
        content: '';
        display: block;
        height: 20px;
      }

      &>span {
        background: $nb-response-bg;
        padding: 10px 15px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .list {
      overflow: auto;
      max-height: 300px;
      margin-top: 2rem;

      .stats-list-item {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 12px;
        color: #747474;

        &:nth-child(even) {
          background: #F7F9FC;
        }

        &:nth-child(odd) {
          background: #EDF0F4;
        }
      }
    }

    .other {
      background: #F8F8F8;
      perspective: 1000px;

      &.collapse {
        display: none;
        opacity: 0;
        animation: animate-list .3s ease-in-out;

        &.show {
          display: block;
          opacity: 1;
        }

        .no-data{
          text-align: center;
          font-size: 25px;
        }
      }

      .responses span {
        background: #FFF;
      }

      .text {
        font-family: Roboto, 'Segoe UI', Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        color: #747474;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      .list {
        &::after {
          content: '';
          height: 2rem;
          display: block;
        }
      }
    }

    &.fetching .recharts-responsive-container {
      opacity: .3;
      filter: grayscale(100%);
    }
  }

  &__chart-wrapper {
    min-height: 250px;
  }

  &__no-data {
    padding: 20px;
    border: 1px solid #f3F3F3;
    border-radius: 8px;
    margin-bottom: 15px;

    .icon {
      width: 120px;
      text-align: center;
      padding-bottom: 10px;
      padding-top: 10px;

      img {
        opacity: 0.4;
        max-width: 120px;
        filter: brightness(120%) grayscale(100%);
      }
    }

    .content {
      padding-left: 0;
      padding-top: 10px;

      .header {
        font-size: 16px;
        padding-bottom: 5px;
      }

      .message {
        font-size: 12px;
      }
    }
  }
}

.responses {
  color: $nb-response-color;
  font-family: Roboto, 'Segoe UI', Helvetica, sans-serif;
  font-style: normal;
  line-height: normal;
}

.srv {
  &__dash {
    margin: 2rem 3rem 3rem 3rem;

    &__title {
      font-size: $font-size-h2;
    }
  }

  &__qst {
    // margin: 1rem 6rem 6rem 6rem;
    // overflow: visible !important;
    border: 1px solid #F0F0F0;
  }

  &__text {
    margin: 1rem 6rem 6rem 6rem;
    padding-bottom: 30%;
    overflow-y: auto;
    height: 300px;
    border: 1px solid #F0F0F0;
    background-color: #fdf8f8;
  }

  &__rat {
    margin: 10px 10px 10px 10px;
    color: #f49649;
    background-color: #fdf8f8;
  }

  &__title {
    font-size: 18px;
  }

  &__response--organization {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: $main-survey-blue;
  }
}

.filter-btn {
  width: 100px;
  height: 50px;
}