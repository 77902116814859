$color-grey-1: #4c4c4c;
$color-grey-2: #3D3D3D;
$color-grey-3: #898989;
$color-grey-4: #F0F0F0;
//$color2: #4FB0AE;
$main-survey-blue: #5081be;
$main-survey-orange : #f49649;
$color-lightgrey : #f5f5f5;
$color-error:#990000;
$color-alert:#D95767;

$menu-font-color: #8d8d8d;
$menu-active-font-color: #2c2b3f;

//font color
$font-color-body : #4c4c4c;

//icon size
$icon-font-size-tiny: 8pt;
$icon-font-size-small: 10pt;
$icon-font-size-medium: 12pt;
$icon-font-size-big: 14pt;
$icon-small-size: 21px;
$icon-big-size: 32px;
$line-height-icon : 35px;


//font size
$font-size-body : 14px;
$font-size-h1 : 24px;
$font-size-h2 : 22px;
$font-size-h3 : 18px;
$font-size-h5 : 12px;
$font-size-badge : 35px;
$line-height-body : 20px;
$line-height-h1 : 35px;

//ToolBar
$toolbarItem-margin : 0 0 8px 0;
$toolbarItem-background : white;
$toolbar-background: #FAFAFA;


//Preview
$main-survey-preview-color: #020c2f;