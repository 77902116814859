@import "global-propierties";

$spacing: calc(50vh - 180px);
$width-icon: 50px;

.survey-preview {
  flex: 1 1 auto;
  width: 100%;
}

.preview {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  z-index: 0;
  background-color: #fafbfb;
  //background: rgb(44, 46, 38) url('https://cn.pling.com/img/d/4/2/8/79779577f314302bbd285e2f858754ea0f04.jpg') no-repeat fixed center;

  #offCanvas-wrapper {
    height: auto;
  }

  &--withoutScroll {
    overflow-y: hidden;
    transform: none;

    .preview__wrapper {
      overflow: hidden;
    }
  }

  &__background {
    display: inline-block;
    z-index: -20;
    line-height: 0;
    opacity: 1;
    background-color: #fafbfb;
    background-size: cover;
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    border-radius: 4px;
    background-position: center top;
    &--bottom {
      position: fixed;
      background-color: #fafbfb;
      bottom: 0;
      height: 1.5rem;
      width: 100vw;
      height: 1.5rem;
      z-index: 10;
    }
  
  }
  &__wrapper {
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    justify-content: center;

    &__item {
      width: 100%;
    }
  }

  &--center {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: $spacing;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: calc(55vh);
    }
  }

  &__resetWrapper {
    z-index: 2;
    display: flex;
    position: fixed;
    right: 15px;
    top: 5px;

    &__reset {
      margin: 10px;
      border: 1px solid transparent;
      border-radius: 30px;
      font-size: $font-size-h3;
      font-weight: bold;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07),
        0 3px 20px 0 rgba(0, 0, 0, 0.07);
      background-color: #020c2f;
      padding: 4px;

      span {
        width: 60px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }

  &__ctrlPanel {
    background-color: #fff;
    width: 100%;
    position: relative;
    height: 63px;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    z-index: 1;
    overflow: hidden;
    &:hover {
      overflow: visible;
    }

    .box-animation {
      width: 100%;
      height: 100%;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      position: absolute;
      transform: translatez(-25px);
      transform-style: preserve-3d;

      &:not(.no-animate) {
        &.animate {
          transform: translateZ(-5px) rotateX(90deg);
        }
      }
    }
    .front {
      transform: rotatex(0deg) translatez(35px);
      position: absolute;
    }
    .back {
      transform: rotatex(-90deg) translatez(25px);
      position: absolute;
    }

    &--top {
      position: fixed;
      top: 0;
      left: 0;
    }

    &--bottom {
      position: fixed;
      bottom: 0;
    }
  }

  &__header {
    width: 100%;
    margin: 0 auto;
    max-width: 75rem;

    .header-icon {
      width: $width-icon;
      height: 35px;
      display: flex;
      box-sizing: border-box;

      .preview-item-order {
        box-sizing: border-box;
      }
      .preview-icon-item {
        height: 35px;
        margin-left: 7px;
        box-sizing: border-box;
      }
    }
    .preview-label {
      display: flex;
      flex: 1;
      margin: 0 !important;
      box-sizing: border-box;
      word-wrap: break-word;
      max-width: calc(100% - #{$width-icon});

      label {
        max-width: 100%;
        display: flex;
      }
    }
    .preview-top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
      overflow: hidden;

      .progression-info {
        display: flex;
        justify-items: center;
        align-items: center;
        flex-shrink: 0;
        margin-left: 0.5rem;
      }
    }

    &__dimension {
      background: $alice-pass;
      height: 100%;
      display: flex;
      align-items: center;

      .title-wrapper {
        font-family: Roboto;
        font-size: 22px;
        line-height: 26px;
        color: $pickled-bluewood;
        display: flex;
        align-items: center;
        overflow: hidden;
        font-weight: normal;
        text-transform: none;
        display: block;
      }

      .progression-info {
        &.mb {
          display: none;
        }
      }
    }

    &__survey {
      display: flex;
      justify-content: space-between;
      box-shadow: none !important;
      align-items: center;
      // width: 100%;
      height: 100%;
      margin: 0 1.5rem;

      .title-wrapper {
        font-family: Roboto;
        font-size: 22px;
        line-height: 26px;
        color: $pickled-bluewood;
        display: flex;
        align-items: center;
        overflow: hidden;
        font-weight: normal;
        text-transform: none;

        .img-wrapper {
          flex-shrink: 0;
        }

        img {
          height: 2.8rem;
          padding-right: 1rem;
          cursor: pointer;
        }
      }

      .top-bar-right {
        ul.menu {
          margin-right: 0 !important;
        }
      }
    }
  }

  .submit-response {
    margin-bottom: 0;
    background: #2495e1;
    border-radius: 4px;
    position: relative;
    padding: 117px 79px 166px 79px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;

    position: absolute; // Pour ajouter de l'animation
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 75rem;
    margin: 0 auto;

    &.ended {
      bottom: calc(50% - 250px);
      transition: bottom 0.5s ease-in-out;
    }

    .send-wrapper {
      position: absolute;
      right: 0;
      left: 0;
      top: -23px;
    }

    .submited-success {
      color: #ffffff;
      text-transform: uppercase;

      .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
      }

      .sub-title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #f3faff;
        margin-top: 1rem;
      }
    }
    .receive-stats {
      background: #fafbfb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 1rem;
      padding: 1.5rem 2rem;
      margin-top: 4rem;
      color: #fff;

      .message {
        font-size: 18px;
        margin-right: 4rem;
      }

      .actions {
        display: flex;

        button {
          color: #fff;
          padding: 0.8rem $spacing-xl;
          cursor: pointer;
          outline: none;
          background: #2495e1;
          border: 0.5px solid rgba(199, 232, 254, 0.5);
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
          border-radius: 50px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          letter-spacing: 0.02em;
          text-transform: capitalize;

          &:not(:last-child) {
            margin-right: 24px;
          }

          &:hover {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.srv-background-color--fill {
  background-color: #fafbfb;
}

.survey-title-preview {
  min-height: 50vh;
  flex: 1;
  background: #FAFBFB;
  align-self: center;


  margin: 4rem;
  padding: 2rem;
  border-radius: 4px;
}

.powered-by {
  position: absolute;
  bottom: 3rem;
  font-size: 12px;
  color: #6D7F92;

  .logo {
    width: 110px;
    margin-left: .5rem;
  }
}
.survey-builder .row .preview {
  height: 100%;
}

.survey-full-page {
  min-height: 89vh;
  height: 100%;
}

.preview_message {
  padding: 5rem 2rem;
  background: #fafbfb;
  border-radius: 4px;
  margin: 2rem;
  width: 100%;
}

.img-meduim {
  max-width: 80% !important;
}

.survey-desc,
.survey-welcome {
  color: $color-grey-1;
  font-size: 16px;
  margin-top: 0 !important;

  p {
    margin-bottom: 0;
  }
}

.survey-welcome > * {
  text-align: center;
}

.wm-wrapper {
  padding: 36px 30px 36px 30px;
}

.template-picker {
  height: 100%;
  width: 100%;

  &__preview {
    max-height: 100%;
    overflow-y: auto;
    padding: 0;
  }

  &__header {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.07);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      text-align: center;
      text-align: center;
      font-size: 19px;
    }
  }

  &__wrapper {
    padding: 0;

    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: inset 1px 0 rgba(0, 0, 0, 0.07);
  }

  &__button-wrapper {
    display: flex;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.07);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 40px;

    & > div {
      flex: 1;
    }

    button {
      padding: 16px;
    }
  }

  &__list {
    flex: 1;
    padding-bottom: 24px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__card {
    border: 3px solid transparent;
    height: 200px;
    border-radius: 2px;
    cursor: pointer;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-in;

    &.is-active {
      border: 4px solid #5081be;
    }
  }

  & > .template-picker__preview > .preview {
    height: 100%;
  }
}

.survey-description-wrapper {
  background: $athens-gray-lighter;
  border: 1px solid #eef6ff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;

  .survey-desc {
    font-family: Roboto;
    font-size: 14px;
    color: $fiord;
    height: 245px;
    overflow-y: scroll;
    font-weight: normal;
  }
}

.pre-preview {
  flex: 1;
  background: url(/img/background/points.png);
  background-position: center;
  background-size: cover;

  &__wrapper {
    background: #ffffff;
    border: 6px solid $athens-gray-lighter;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    font-family: Roboto;

    max-width: 600px;
    margin: 8rem auto;
  }
  &__title {
    background: $athens-gray-lighter;
    border-radius: 5px 5px 0px 0px;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.04em;
    color: rgba(109, 127, 146, 0.5);
    text-align: center;
    padding: $spacing-l 0;
    text-transform: uppercase;
  }

  &__sub-title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: $pickled-bluewood;
    padding: $spacing-m 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    &::after,
    &::before {
      content: "";
      height: 1px;
      background: $athens-gray;
      flex: 1;
    }

    &::after {
      margin-left: $spacing-m;
    }

    &::before {
      margin-right: $spacing-m;
    }
  }

  &__content {
  }
}

@media only screen and (max-width: 768px) {
  .preview__header {
    align-items: center;
    display: flex;
    justify-content: center;

    .survey-font__top-bar {
      display: none;
    }
  }

  .survey-title-fetching {
    svg {
      width: 80%;
    }
  }

  .preview__header__dimension .preview-top-bar {
    margin: 0 2rem;
    width: 100%;
  }

  .preview {
    .submit-response {
      padding-left: 2rem;
      padding-right: 2rem;

      .receive-stats .message {
        margin-right: 2rem;
      }

      &.ended {
        bottom: 0 !important;
        top: 0;
        padding-top: 14rem;
      }
    }

    &__background {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &--bottom {
        display: none;
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .survey-title-fetching {
    svg {
      width: 100%;
    }
  }

  .preview__header__survey {
    .title-wrapper .img-wrapper {
      display: none;
    }
  }

  .preview__header__dimension .preview__header {
    .progression-info {
      &:not(.mb) {
        display: none;
      }
      &.mb {
        display: block;
      }
    }
  }

  .preview .submit-response {
    &.ended {
      padding-top: 10rem;
    }

    .receive-stats {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .message {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .actions {
        text-align: center;
        display: block;

        button {
          &:not(:last-child) {
            margin: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  .preview .submit-response {
    &.ended {
      bottom: unset !important;
      min-height: 100vh;
    }
  }
}
