@mixin display-flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin display-inline-flex() {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
