.float-menu {
  &__wrapper {
    position: absolute;
    z-index: 10;
  }
  &__icon {
    box-sizing: border-box;
    border: 1px solid #71cbea;
    background-color: white;
    padding: 5px;
    margin: 0;
    border-radius: 18px;
    cursor: pointer;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center !important;
    z-index: 3;
  }

  &__content{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background: #efefef;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
    z-index: 3;
    box-sizing: border-box;
    margin-top: 8px;
    border-radius: 3px;

    &::before{      
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;

      border-color: rgba(221, 221, 221, 0);
      border-bottom-color: #ddd;
      border-width: 6px;
      margin-left: -6px;
    }
    &::after{
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;


      border-color: rgba(251, 251, 251, 0);
      border-bottom-color: #fbfbfb;
      border-width: 4px;
      margin-left: -4px;
    }
  }

  &__elem{
    display: flex;
    padding: 5px;
    align-items: center;
    line-height: 1;
    cursor: pointer;

    &:hover{
      background-color: #F0F0F0;
    }
    &__icon{
      padding-right: 5px;
      font-size: 12pt;
    }
  }
}