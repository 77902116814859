@import "../../components/global-propierties";

.profiling {
  background: #FFFFFF;
  border: 1px solid rgba(229, 229, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  position: relative;

  .title {
    font-family: $main-header-font;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    color: #5282BC;

    padding-top: 15px;
  }

  .reset {
    // z-index: 2;
    display: -moz-box;
    display: -ms-flexbox;
    position: absolute;
    right: 5px;
    top: 5px;

    .reset-icon {
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 6px;
      margin: 8px;
      font-size: 14px;
      color: #5282BC;
      cursor: pointer;
      display: flex;
      justify-content: center;

      span {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          transition: transform .5s ease-in-out;
        }
      }

      &:hover {
        background: #75757529;
      }

      &:active {
        i {
          transition: .5s;
          transform: rotate(150deg);
        }
      }
    }
  }
}

.profiling-wrapper {
  padding-left: 0;
}

.profiling-item {
  margin-bottom: 1rem;
  padding-right: 0;

  .title {
    width: 100%;
    display: inline-block;
    padding: 0.33333rem 0.5rem;
    border-radius: 0;
    white-space: nowrap;
    cursor: default;
    background: #F2F7FC;
    color: #3F3F3F;
    margin-bottom: 2px;
    font-family: Helvetica;
    font-size: 14px;
    line-height: normal;
    border-radius: 4px;
  }

  &--lng {
    width: 100px !important;
  }

  .content {

    select,
    input {
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      box-shadow: none;
      color: #3F3F3F;
      font-size: 14px;
      font-family: Roboto, 'Segoe UI', Helvetica, sans-serif;

      &:disabled {
        border: 1px solid #f3efef5c;
        background: #e6e6e647;
      }
    }

    .input-group {
      &> :not(:first-child) {
        margin-left: 5px;
      }
    }

    .operator {
      width: 50px;
      margin: 0px;
      height: 100%;
    }
  }

  .button {
    background-color: #f2f7fc;
    color: #3f3f3f;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 600;
    border-radius: 2px;
    outline: none;

    &:hover,
    &:focus {
      color: #3F3F3F;
      background-color: #e2ebf5;
    }
  }
}

.srv-tooltip {
  position: relative;
  display: inline-block;
}

.srv-tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: #3b679e;
  padding: 15px;
  margin-right: 20px;
  color: white;
  white-space: pre-wrap;
}

.srv-tooltip:hover .tooltiptext {
  visibility: visible;
}

.greetings {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-content {
  white-space: pre-wrap;
  line-height: 1.2;
}

.react-tooltip {
  max-width: 350px;
}