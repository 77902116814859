#select-community {
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/img/home/select-community-bg.jpg') no-repeat center center
    fixed;
  background-size: cover;

  .sc__container {
    width: 100%;
    min-height: 100%;
    padding: 6rem;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sc__logo {
    width: rem-calc(128px);
    height: auto;
    margin-bottom: 4rem;
  }

  .sc__communities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .sc__community {
    margin: 1rem;
    position: relative;
    width: calc((100% - 2.1rem) / 3); // 3 columns - 2 * margin
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba($main-blue, 0);
      transition: nth($transitions, 3);
    }

    &:hover {
      &::before {
        background-color: rgba($main-blue, 0.7);
        transition: nth($transitions, 3);
      }
    }

    &-logo {
      width: 100%;
      height: rem-calc(160px);
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
