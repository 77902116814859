/*
 * $blue: #5081BE;
 *
 * icone source : http://www.flaticon.com/categories/business/11
 */


#home-page , .home-page {
  background-color: #FFF;
  flex: 1 1 auto;
  font-family: $main-body-font;

  .home-page__sep::before{
    content: ' ';
    display: inline-block;
    border-bottom: 1px solid $main-medium-gray;
    width: 4rem;
  }

  &__section {
    margin-bottom: $spacing-xxl;
    color: #3C4E64;

    .iconbox {
      img {
        width: 230px;
      }
    }

    .content {
      margin-top: auto;
      margin-bottom: auto;

      ul {
        margin: 0;
      }
    }

    p, ul {
      font-weight: normal;
    }

    h4{
      font-weight: 500;
      font-family: $main-body-font;
    }
    h6 {
      font-family: $main-body-font;
    }
  }

  .animated-header {
    position: relative;
    min-height: 550px;
    padding-bottom: 4rem;
    
    &__title {
      position: absolute;
      top: 40px;
      left: 80px;
      z-index: 1;
      color: #29394D;
      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 1;
        margin: 0;
      }
      h4 {
        font-size: 20px;
        span {
          font-weight: 300;
          color: #18A0FB;
        }
      }
    }

    &__animation {
      z-index: -1;
    }

    video {
      position: absolute;
      width: 40%;
      height: auto;
      top: 50%;
      left: 50%;
      z-index: 1;
      border-radius: 5px;
      background-color: #F1F2F4;
      box-shadow: 1px 1px 2px 2px rgba(109, 127, 146, 0.1);
    }
  }

  .article.article--vertical{
    .article__info {
      // font-size * line-heigth + article_title heigth + article_title margin + article_content padding + article_content heigth
      height: rem-calc(14px)*1.5 + 2 * 1.4 * 1.4rem + 2rem + 2rem + 6 * 1.6 * rem-calc(14px);
      overflow: hidden;
    }

    .article__content{
      max-height: 6 * 1.6 * rem-calc(14px);
      overflow: hidden;
    }

    .article__title {
      max-height: 2 * 1.4 * 1.4rem;
      overflow: hidden;
    }
  }

  .medium-centered {
      float: none;
      margin: 0 auto;
  }
  .large-feature{
    margin: 55px 0;
    .columns img {
      width: 55%;
      height: auto;
    }
  }
  .text-right{
    text-align:right;
  }
  .vertical-feature-block{
    text-align: center;
    img {
        display: block;
        height: auto;
        margin: 1rem auto 0;
        padding: 1.25rem;
        width: 40%
      }
  }
  hr.solid{
    margin: 50px auto;
    width: 25%;
  }
}