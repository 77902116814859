.tab-pane{
  height: 0;
  overflow: hidden;
  display: none;
}
.tab-pane.active{
  display: flex;
  height: auto;
  opacity: 1;
}
.tabs-wrapper{
  display: flex;
  margin: 0 0 1rem;
  li{
    cursor: pointer;
    border-bottom: 3px solid #eee;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
  }
  li.active{
    color: #505050;
    margin-bottom: -1px;
    border-bottom: 4px solid #505050;    
  }
  .icon{
    font-size: 1rem;
    margin-right: .5rem;
  }
}