.ttp-drop-down-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0;
  width: 25px;
  height: 25px;
  
  &.vertical {
    .icon-options {
      transform: rotate(90deg);
    }
  }

  &.right {
    ul {
      right: unset;
      left: 50%;
    }
  }

  &.active {
    background: #dcdddd;
    border-radius: 4px;
  }

  &.hover {
    ul {
      visibility: hidden;
    }

    &:hover {
      ul {
        visibility: visible;
      }
    }
  }

  .icon-options {
    display: flex;
    justify-content: center;
  }

  ul {
    position: absolute;
    top: 100%;
    right: 50%;
    z-index: 2;
    margin: 0;
    padding: 8px 0;
    border-radius: 8px;
    transition: nth($transitions, 2);
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
  }

  .centered-list {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
  }

  li {
    margin: 0;
    color: #000;
    line-height: 2rem;
    position: relative;
    text-align: left;
    top: 1px;
    cursor: pointer;
    white-space: nowrap;

    a {
      color: $color-grey-1;
      white-space: nowrap;
    }

    &:first-letter {
      text-transform: uppercase
    }

    &:hover {
      background-color: rgba(0, 0, 0, .07);
    }

    &:hover, &:focus, &:active {
      ul.nested-menu {
        opacity: 1;
        transition: opacity .3s ease-in-out;
      }
    }

    // Nested menu
    ul.nested-menu {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 100%;
      right: auto;
      z-index: 1;
      display: block;
      min-width: 100px;
      background: #fefefe;
      transition: display .3 ease-in-out;
    }


    &.left, &.right {
      position: relative;

      & > a::after {
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: inset 6px;
        content: '';
      }
    }

    &.left {
      & > a::after {
        right: auto;
        left: 5px;
        top: calc(50% - 6px);
        border-left-width: 0;
        border-right-style: solid;
        border-color: transparent #cbcbcb transparent transparent;
      }

      ul.nested-menu {
        top: 0;
        right: 100%;
        left: auto;
      }
    }

    &.right {
      & > a::after {
        right: 5px;
        left: auto;
        top: calc(50% - 6px);
        border-right-width: 0;
        border-left-style: solid;
        border-color: transparent transparent transparent #cbcbcb;
      }

      ul.nested-menu {
        top: 0;
        right: auto;
        left: 100%;
      }
    }
  }
}