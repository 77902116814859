@import "../global-propierties";
$max-width-choices: 840px;
$min-width-choices:200px;

.block-choices {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  .choices-column {
    // min-width: $min-width-choices;
    min-width: 80%;
    display: table;
    table-layout: fixed;
    width: auto;
    box-sizing: border-box;

    .label-choice {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // min-width: 38rem;
      max-width: 56rem;
      padding: 8px 10px;
      border-radius: 40px;
      cursor: pointer;
      background: #F1F2F4;
      border: none;

      font-family: Roboto;
      font-size: 16px;
      color: #29394D;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #F3FAFF;
      }

      &.selected {
        color: #FFF;
        background: linear-gradient(270deg, #18A0FB 0.02%, #2495E1 100%);
      }

      &::placeholder {
        color: rgba($main-survey-preview-color, 0.5);
      }
      &__input {
        border-radius: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        border-bottom: 1.5px solid #F1F2F4 !important;
        font-family: Roboto;
        font-size: 16px;
        color: $pickled-bluewood;
      }
      .title-choice {
        display: flex;
        align-items: center;
        width: 100%;

        .choice-icon {
          min-width: 20px;
          height: 20px;
          margin-right: 2.5rem;
          margin-left: 1rem;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          box-sizing: border-box;
          position: relative;

          font-family: Roboto;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #2495E1;

          &.selected {
            color : #FFF;

            &::after {
              background: transparent;
            }
          }


          &::after {
            content: '';
            display: inline-block;
            background: #FAFBFB;
            width: 1px;
            height: 130%;
            margin-top: -15%;
            position: absolute;
            margin-left: 1.25rem;
            left: 100%;
          }
        }
        .content-choice {
          margin-right: 8px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          textarea {
            height: 30px;
            min-height: 30px;
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
          &__wrapper {
            display: flex;
            flex-direction: column;  
          }
        }
        .choice-validated {
          margin-left: auto;
          font-size: $icon-font-size-medium;
          overflow: hidden;
          opacity: 1;
          width: $icon-small-size;
          min-width: $icon-small-size;
          height: $icon-small-size;
          stroke: currentColor;
          stroke-width: 2px;
          box-sizing: border-box;
        }
      }
    }

    .other {
      margin-top: 2.5rem;
      margin-left: 4rem;

      textarea {
        &::placeholder {
          color: $slate-gray;
          font-family: Roboto;
          font-size: 14px;
        }
      }
    }
  }
}

.visually-hidden {
  box-sizing: border-box;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: none;
}




@media only screen and (max-width: 768px) {
  .block-choices .choices-column {
    min-width: 80%;

    .label-choice {
      min-width: unset;
      max-width: unset;

      .title-choice {
        .choice-icon {
          margin-left: 0;
          margin-right: 1rem;
  
          &::after {
            margin-left: .4rem;
          }
        }
      }
    }

    .other {
      margin-left: 2rem;
    }
  }
}

