@import 'global-propierties';

.anonymous {
  &__email {
    background: #f8f9fa;
    border: 1px solid #f1f2f4;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 2rem 2.5rem;
    margin-bottom: 1rem;
    align-items: center;
    padding: 0.5rem 1rem;
    display: flex;
    position: relative;

    span {
      img {
        border-left: 1px solid #f1f2f4;
        width: 22px;
      }

      &::after {
        content: '';
        display: block;
        height: 100%;
        border-right: 1px solid #f1f2f4;
        position: absolute;
        top: 0;
        margin-left: 2.5rem;
      }
    }

    input {
      font-family: Roboto;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      margin-left: 2rem;
      color: $pickled-bluewood;

      &::placeholder {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #6d7f92;
      }
    }
  }

  &__forms {
    input[type="text"] { 
      border-bottom: 1px solid #a9b4bf !important;

      &:focus {
        border-bottom: 1px solid #c7e8fe !important;
      }
    }
  }
  &__errors {
    margin: 1rem 2.5rem;
    color: $coral-red;
    padding: 0 1rem;
    font-weight: normal;
    font-family: Roboto;
  }

  &__login {
    border-top: 1px solid #f1f2f4;
    padding: 1rem 2.5rem;
    text-align: center;
    font-family: Roboto;
    font-weight: normal;

    a {
      color: #18a0fb;
    }
  }

  .tt-button-group {
    text-align: center;
  }

  .members-only {
    .message {
      text-align: center;
      font-size: 18px;
    }

    .connect {
      margin: 1.5rem 1rem;
      text-align: center;
      font-size: 15px;
    }
  }

  .infos {
    background: #f8f9fa;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;

    &.warning {
      background: #fcf2e3;
    }
  }
}
