// .page-header + * {
//   margin-top: $spacing-xl;
// }

.page-header {
  display: flex;
  background: $alice-pass;
  padding: $spacing-s $spacing-xl;
  margin: 0 $spacing-s;
  border-radius: 0px 0px 10px 10px;
  
  &__icon {
    font-size: 2rem;
    color: $pickled-bluewood;
    padding-right: $spacing-s;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      height: 100%;
      width: 1px;
      background: #fff;
      box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.41);
    }
  }

  &__info {
    padding-left: $spacing-l;

    .title {
      font-family: Roboto;
      font-weight: normal;
      font-size: 1.5rem;
      color: $pickled-bluewood;
      text-transform: uppercase;
  
      &.sub-title {
        font-size: .75rem;
        color: $fiord;
      }
    }
  }

  &__actions {
    margin: auto;
    margin-right: 0;
    display: flex;

    & > :not(:last-child) {
      margin-right: $spacing-s;
    }
  }
}
