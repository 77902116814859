@import "../global-propierties";

.themes-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.themes-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color:  #8d8d8d;
  box-shadow: 0 -1px rgba(134, 153, 158, 0.07) inset;
  // cursor: pointer;
  transition: background-color 200ms;
  border-left: 3px solid transparent;

  &.is-current {
    background: #ddd;
    padding-right: calc(10px - 3px);
    border-left: 3px solid #5081be;
  }
}

.themes-name {
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  width: 100%;
}
.themes-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  max-width: 60%;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
}
.themes-font {
  font-size: 12px;
  line-height: 18px;
  color: #AAA;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.themes-colors {
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  display: flex;
  padding: 5px;
  background-size: cover;
  background-position: center;
  background-color: #FFF;
}
.themes-color {
  width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.themes-tools {
  margin-left: 4px;
}

.themes-button-wrapper {
  display: flex;
  font-size: 14px;
  line-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 24px 4px;
  z-index: 100;
  padding: 25px 20px;

  & > :not(:first-child) {
    margin-left: 4px;
  }

  & button {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    min-height: 32px;
    font-size: 14px;
    line-height: 20px;
    border-width: 0px;
    transition: 0.4s;
    padding: 6px 12px;
    border-radius: 3px;

    &.action {
      width: 100%;
      background-color: rgb(45, 45, 53);
      color: rgb(255, 255, 255);

      &:hover {
        background-color: rgb(109, 104, 104);
        transition: 0.2s;
      }
    }
  
    &.inaction {
      background-color: rgb(71, 71, 71);
      color: rgb(38, 38, 39);

      &:hover {
        background-color: rgba(212, 212, 212, 0.208);
        transition: 0.2s;
      }
    }
  }
}

// Custom Themes 

.themes-name-input {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  width: 100%;

  & input {
    margin: 3px;
    border-radius: 3px;
    outline: none;
    max-width: 95%;
    padding: 0 8px;
    height: 30px;
    //background-color: transparent;
    box-shadow: none;
    font-size: 14px;
  }
}

.themes-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;


  font-size: 14px;
  line-height: 30px;
  box-shadow: 0 -1px rgba(0,0,0,.07) inset;
  padding: 10px 20px;
}
.themes-property {
  height: 100%;
  padding-right: 18px;
  text-transform: capitalize;
}
.themes-select-font {
  border-radius: 3px;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  user-select: none;
  outline: none;
  width: 100%;
  min-width: 128px;
  max-width: 60%;

  & select {
    margin: 0;
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    padding: 0 8px;
    padding-right: 24px;
  }
}

.themes-color-picker {
  box-sizing: border-box;
  position: relative;
  text-align: left;
  color: #262627;
  outline: none;
}
.color-picker-wrapper {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 3px;
    height: 32px;
    line-height: 32px;
    background-color: #FFF;
    cursor: pointer;
}
.color-picker-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-picker-dropdown {

}

.popover {
  position: absolute;
  right: 0;
  margin-top: 4px;
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  outline: 0;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}