@import "global-propierties";

.btn-popup{
  margin-left: 6px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px 11px;
  min-height: 32px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  margin-bottom: 0;
  font-weight: 500;
  color: $color-grey-1;
  background-color: rgba(212,212,212,0.3);

  &--alert{
    color: #ffffff;
    background-color: $color-alert;

    &:hover {
      background-color: rgba($color-alert, .7);
      transition: 0.2s;
    }
    &:focus{
      background-color: rgba($color-alert, .7);
      outline: none;
    }
  }
  &--default {
    &:hover {
      background-color: rgba(0,0,0,.07);
      color: $color-grey-1;
      transition: 0.2s;
    }
    &:focus{
      background-color: rgba(0,0,0,.07);
      color: $color-grey-1;
      outline: none;
    }
  }
  &--main {
    color: #ffffff;
    background-color: $main-blue;

    &:hover {
      background-color: rgba($main-blue,.7);
      transition: 0.2s;
    }
    &:focus{
      background-color: rgba($main-blue,.7);
      outline: none;
    }
  }
  &--success{
    color: #ffffff;
    background-color: #51a351;

    &:hover {
      background-color: rgba(#51a351,.7);
      transition: 0.2s;
    }
    &:focus{
      background-color: rgba(#51a351,.7);
      outline: none;
    }
  }
}

.srv-reveal{
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 2px 12px rgba(0,0,0,.06);

  &:focus{
    border-color: transparent;
    outline: none;
  }

  &__label {
    border-radius: 2px;
    min-width: 100px;
    text-align: center;
    padding: 10px;
    &--success {
      background: #51a351;
      color: white;
    }
  }

  &__block{
    padding-top: 10px;
  }

  &__prg-meter-txt{
    left : 15px;
  }

}
.modal-title{
  padding: 20px 20px 20px 20px;
}

.rmodal-wrapper {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  max-width: 860px;
  min-width: 600px;
}

.rmodal-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.rmodal-content {
  padding: 10px 15px;
}

.rmodal-action {
  margin-top: 15px;
  text-align: right;
  padding: 10px;
  border-top: 1px solid rgb(238, 238, 238);
  overflow: hidden;
}

.rmodal-close {
  outline: none;
}

.rmodal-checkboxs-container {
  margin-left: 30px;
}