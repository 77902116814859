.campaign-stats {
  padding-top: 2rem;

  &__header {
    border-left: 4px solid #5081be;
    padding-left: .5rem;
    text-transform: uppercase;
    color: #3F3F3F;
    font-weight: 600;
    font-size: 14px;
  }

  &__list {
    padding-top: 2rem;

    .list-header {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #3F3F3F;
      padding-bottom: .5rem;
    }

    .item {
      padding-top: 10px;
      padding-bottom: 10px;
      max-width: 100%;
      &:nth-child(even) {
        background: #F7F9FC;
      }

      &:nth-child(odd) {
        background: #EDF0F4;
      }

      .cible {
        font-weight: 600;
        color: #3f3f3f;
      }
    }

    .cible {
      text-align: center;
    }

    .no-data {
      text-align: center;
    }

    .sub-header {
      font-size: 10px;
      text-transform: none;
    }
  }

  .wrapper {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;

    .rank {
      font-size: 100px;
      line-height: 1;
      font-weight: bold;
    }

    .text {
      font-size: 30px;
      line-height: 1;
    }

    .rank-line {
      border: 10px solid #e9ebee;
      width: 75%;
      margin-top: 2rem;
    }
  }

  .campaign-creator{
    padding: $spacing-s;
    margin-bottom: $spacing-l;
  }

  .note-wrapper {
    box-shadow: 1px 0px 10px 2px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 4px;
  }
}

.button-menu {
  position: relative;
  padding: $spacing-s 4.5rem;
  background: #18A0FB;
  border-radius: 4px;
  outline: none;

  & > span {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFF;
  }

  ul {
    z-index: 2;
    margin: 0;
    display: none;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);

    li {
      padding: .8rem $spacing-m;
      padding-right: $spacing-s;
      cursor: pointer;
      text-align: left;

      li {
        padding: 0;
        display: flex;
        
        a {
          padding: .8rem $spacing-m;
          padding-right: $spacing-s;
        }
      }

      a {
        color: $fiord;
      }

      &:hover {
        background: $athens-gray;

        & > ul {
          display: block;
          visibility: visible;
          top: 0;
          background: #fff;
          box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
          left: 100%;
          margin: 0;
          width: 13rem;
        }
      }

      &.child-menu {
        position: relative;

        &::after {
          display: inline-block;
          margin-left: 1.5em;
          content: "";
          border-left: 0.5em solid;
          border-top: 0.4em solid transparent;
          border-right: 0;
          border-bottom: 0.4em solid transparent;
          color: $fiord;
        }
      }

      .number {
        background: $french-pass;
        padding: 0.1rem .5rem;
        border-radius: 4px;
      }

      .language {
        text-transform: uppercase;
        background: $magic-mint;
        padding: 0.1rem .5rem;
        border-radius: 4px;
      }
    }
  }

  &:hover {
    & > ul {
      display: block;
      visibility: visible;
    }
  }

  &::after {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
    color: #FFF;
  }
}