@import "../global-propierties";

%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.star-rating {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  color: $main-survey-blue;
  display: flex;

  // TODO THEME
  & > .star-rating__star {
    color: $french-pass;

    // &.is-hover {
    //   color: $french-pass;
    // }

    &.is-selected, &.is-hover {
      color: $curious-blue;
    }
  }

  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 4em;
    color: $main-survey-blue;
    transition: color .2s ease-out;

    &:hover {
      cursor: pointer;
      color: rgba($main-survey-blue,.07);
    }

    &.is-hover {
      color: rgba($main-survey-blue,.7);
    }


    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}

@media only screen and (max-width: 600px) {
  .star-rating__star {
    font-size: 3em;
  }
}