#surveyLibrary {

  .tab-pane {
    overflow: visible;
    padding-bottom: 10%;
    background: #fafbfb;
    padding-top: 2rem;
    max-width: 100%;
  }

  .library-actions {
    border-radius: 4px;
    width: 25px;
    height: 25px;

    .tt-drop-down-menu {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background: #dcdddd;
    }
  }

  // .empty-data {
  //   padding-bottom: 10%;
  //   padding-top: 4rem;
  // }
}