.participant-user {
  padding-bottom: 5rem;
  margin-top: 2rem;

  table {
    // font-size: 13px;

    td, th {
      &:nth-last-child(2) {
        border: none;
      }
    }
  }
  .label {
    border-radius: 30px;
  }
  .email{
    max-width: 200px;
  }
  .revival {
    padding-right: 8px;
  }
}

.suggested-campaign{
  margin-top: 1rem;
}