.language-setter {
  ul {
    padding-top: 20px;
    margin: 0;

    li {
      background: #FFFFFF;
      border: 0.5px solid #C7E8FE;
      box-sizing: border-box;      
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
      border-radius: 50px;
      font-family: Roboto;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      cursor: pointer;
      color: #18A0FB;

      margin: 1rem auto;
      margin-top: 0;
      padding: .8rem 1rem;
      max-width: 200px;
      text-align: center;

      &:hover{
        opacity: 0.8;
        box-shadow: none;
      }

      &.disabled {
        background: #F8F9FA;
        opacity: 0.5;
        box-shadow: none;
        border: none;
      }

      .icon-wrapper {
        margin-right: $spacing-s;

        img {
          width: 20px;
        }
      }
    }

    .disabled {
      pointer-events:none;
      opacity:0.6;
    }
  }
}
