.not-found {
  // max-width: 100%;
  background: #fff;
  padding: 5rem 0;

  .not-fount__main {
    background: url('/img/404.jpg') no-repeat 38% !important;
    color: #fff;
    font-size: 1.5rem;
    display: flex;

    &>div {
      height: 35.8rem;
      border-left: 1rem solid #fff;
      text-align: center;
      padding-top: 4rem;
    }

    .not-fount__number {
      font-size: 10rem;
      color: #fff;
    }

    button a {
      color: #fff;
      margin-left: 1rem;
      font-weight: bold;
    }
  }
}

.not-found-iframe {
  width: 750px;
}