
.authorisation-settings {
  padding: 4rem 0 0;
  position: relative;
  .sidebar{
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 75%;
    right: 0;
    z-index: 2;
    transform: translateX(100%);
    transition: nth($transitions, 5);
    &.display {
      transform: translateX(0);
      box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, .3);
    }
  }

  &__newuser {
    border-top: 1px solid #ccc;

    &-link {
      padding: 2rem 0;
      font-size: 1.05rem;
      color: $main-blue;
    }
  }

  .filter-bar {
    box-shadow: $shadow-like-border;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    input {
      border: 0;
      box-shadow: none;
      margin: 0;
      font-size: .9rem;
    }
  }
  .filter-bar__roles {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-right: 1px solid #efeff0;
    font-size: .9rem;
    position: relative;
    & > .icon {
      cursor: pointer;
    }
  }
  .role-list {
    position: absolute;
    top: 3.4rem;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    box-shadow: $shadow-like-border;
    ul {
      margin: 0;
    }
    .block-role {
      border-bottom: 1px solid #efeff0;
      &.all {
        padding: 1rem;
      }
      &.all, li {
        cursor: pointer;
        transition: nth($transitions, 2);
        &:hover {
          background-color: $main-blue;
          font-weight: bold;
          color: #fff;
        }
      }
      li {
        padding: .5rem 1rem .5rem 2rem;
      }
    }
    h5 {
      font-weight: bold;
      color: $h-color;
      font-size: .9rem;
      padding: 1rem 1rem 0 1rem;
    }
  }
  .search-icon {
    padding-right: .5rem;
    color: #BBB;
    font-size: 1rem;
  }
  .user {
    justify-content: flex-start;
    height: auto;
    margin: 0 0 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-avatar span {
      font-size: 2.5rem;
    }
    h3 {
      font-weight: bold;
      font-size: 1rem;
      margin: 0;
      color: $gray-dark;
    }
    h5 {
      font-size: .8rem;
      font-weight: 600;
    }
    h4 {
      color: $main-blue;
      font-weight: 600;
      font-size: .8rem;
      text-transform: uppercase;
      margin: .2rem 0 .3rem;
      text-align: center;
      &:after {
        content: "";
        width: 4rem;
        margin-top: .7rem;
        border: 1px solid $main-orange;
      }
    }
    &-avatar {
      position: relative;
      cursor: pointer;
      overflow: inherit;
      opacity: 0.9;
      border-radius: 50%;
      background-size: cover;
      background-position: 50%;
      width: 8rem;
      height: 8rem;
      margin-bottom: 1rem;
      text-align: center;
      &.selected, &:hover {
        opacity: 1;
      }
      &:before, &:after {
        content: '';
        width: 110%;
        height: 110%;
        position: absolute;
        top: -5%;
        left: -5%;
        border-radius: 100%;
        z-index: 0;
      }

      &:before {
        border: .2rem solid transparent;
      }

      &.selected:before {
        border-top-color: $main-blue;
        border-right-color: $main-blue;
        border-bottom-color: $main-blue;

        transition:
          border-top-color 0.15s linear,
          border-right-color 0.15s linear 0.10s,
          border-bottom-color 0.15s linear 0.20s;
      }

      &:after {
        border: 0 solid transparent;
      }

      &.selected:after {
        border-top: .2rem solid $main-blue;
        border-left-width: .2rem;
        border-right-width: .2rem;
        transform: rotate(255deg);
        transition:
          transform 0.4s linear 0s,
          border-left-width 0s linear 0.35s;
      }
    }
    &-check {
      position: absolute;
      top: 0;
      left: 0px;
      font-size: 1.7rem;
      background-color: #fff;
      height: 2.2rem;
      width: 2.05rem;
      border-radius: 50%;
      color: #CCC;
      cursor: pointer;
      z-index: 1;
      .active {
        transition: nth($transitions, 2);
        color: $main-blue;
      }
    }
    &-header , h4{
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }
}
#authorisation-setting-users {
  padding: 4rem 0;
  max-width: inherit;
  .setting-user {
    text-align: center;
  }
}
#bubbles {
  position: fixed;
  top: 15rem;
  right: 1rem;
  font-size: 2rem;
  color: #fff;
  & > div {
    background-color: #373c44;
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 55%;
    height: 3.7rem;
    width: 3.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    transition: nth($transitions, 5);
    transform: translateX(130%);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    i {
      z-index: 2;
    }
    span {
      position: absolute;
      right: 47%;
      background-color: #373c44;
      width: 14rem;
      font-size: .9rem;
      text-transform: uppercase;
      text-align: center;
      padding: 1.2rem 2rem;
      border-radius: 2rem 0 0 2rem;
    }
    &:hover {
      overflow: visible;
    }
    &.active {
      transform: translateX(0);
    }
  }
}
#authorisation-settings__sidebar {
  overflow-y: scroll;
  & .preferences-switch:last-child{
    padding-bottom: 7rem;
  }
  ul {
    margin: 0;
  }
  .sidebar__user {
    margin: 3rem 0 2rem;
    h3{
      font-size: 1rem;
    }
    h4{
      font-size: .8rem
    }
  }
  .switches, .switch.mandated, .switch.box, .preferences-switch {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-left: 1.5rem;
    li:last-child {
      border-bottom: none;
    }
  }
  .switch {
    display: flex;
    justify-content: space-between;
    color: $main-color;
    font-weight: 500;
    margin: 0;
    height: 3rem;
    align-items: center;
    padding-right: 1.5rem;
    border-bottom: 1px solid #EEE;
    letter-spacing: .02rem;
    &.mandated.tags {
      margin-bottom: 100px;
    }
  }
  .separator {
    padding: .6rem;
    background-color: #F5F5F5;
    color: #aaa;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .side__bar-group {
    margin: 2rem 0;
  }
  .side__bar-group-avatar {
    display: flex;
    justify-content: center;
    .user-avatar {
      margin-left: -2.5rem;
      opacity: 1;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .side__bar-group-names {
    padding: 1.5rem;
    font-weight: 400;
    text-align: center;
  }
}
