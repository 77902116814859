#quiz-results {
  padding: 2rem 0;

  .header {
    align-items: center;

    .left {
      text-align: right;
      padding: 0 1rem;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      color: #28394d;
      text-transform: uppercase;
    }

    .right {
      text-align: left;
      padding: 0 1rem;
    }
  }

  .question-ranking {
    padding: 0 1rem;

    .user-wrapper {
      padding: 1rem;

      .wrapper {
        display: flex;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-weight: bold;
        color: #29394d;

        .rank {
          padding: .5rem 1rem;
          background: #f2f7fc;
          font-size: 1.8rem;
          font-weight: bold;
          align-items: center;
          display: flex;
        }
        .infos {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          padding: .5rem .8rem;
          font-size: 1.1rem;
          font-weight: 500;

          .score {
            color: #2495E1;
            margin-left: .5rem;
          }
        }
      }
    }
  }

  .quiz-page-message-wrapper {
    flex: 1;
    min-height: 50vh;
    background: #f8f8f8;
    border-radius: 4px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .message {
      margin: auto;
      font-size: 2rem;
      font-family: Roboto;
      font-weight: normal;
      text-transform: uppercase;
      color: #28394d;
    }
  }


  .quiz-participants {
    .processing {
      text-align: center;
      padding: 4rem;
      font-size: 1.2rem;
      font-family: Roboto;
      font-weight: normal;
    }
  }
}