@import "../global-propierties";

.preview-input {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  color: $pickled-bluewood;

  .transparent-input {
    padding: 0 0 15px;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.25;
    border-bottom: 1.5px solid $black-haze !important;
    color: #29394D;

    &::placeholder {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: $slate-gray;
    }
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  p {
    margin: 5px 0 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    padding: 0;
  }
}
