@import "../mixin";
@import "../global-propierties";

$padding-icon-wrapper : 24px;
$padding-icon-wrapper-top: 24px;
$width-icon-block: 52px;
$height-icon: 24px;
$bullet-size: 8px;
$padding-child-block: 55px;


@keyframes pulse_animation {
  50% { opacity: 1; }
}

.editor {
  padding: 0px;
  height: 100%;
  border-right: 1px solid rgba(0,0,0,.07);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #d8d8d8;
}

.editor_container {
  opacity: 1;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: 840px;
  padding: 36px 30px;
  padding-bottom: 400px;
  background: #fff;
  position: relative;
  margin-top: 50px;
}

.empty-editor {
  padding-top: 40px;
  margin-left: 30px;
}

.editor-block {

  &__container {
    width: 100%;
    position: relative;

    .block-buttons {
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
      right: -14px;
      transform: translateY(-50%);
    }
  }

  &__root {
    position: relative;
    display: flex;
    z-index: 1;
    flex-direction: column;
    .header-label {
      display: flex;
    }
  }

  &__actions {
    position: absolute;
    bottom: -10px;
    left: 0;
    padding-left: 80px;
    display: flex;
    opacity: 0;
    align-items: center;
    color: #3C4E64;

    &.block {
      position: static;
      padding: 2rem 1rem;
      padding-left: 2.5rem;
      opacity: 1;
    }

    &.visible {
      opacity: 1;
      z-index: 8;
      transition: all 0.2s ease 0s;
    }

    & > div {
      margin-right: .8rem;
    }
  }

  &__focused {
  }

  &__background {
    z-index: -1;
    opacity: 0;
    background-color: rgb(248,248,248) !important;
  }

  &__focused &__background {
    opacity: 1;
  }

  &__filter {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &--child {
      left: -$padding-child-block;
    }
  }

  &__pulse {
    border: 2px dashed #74cce9bd;
    left: -55px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;

    &.animate {
      animation: pulse_animation .5s 2 ease-out;
    }
  }

  &__child {
    padding-left: $padding-child-block;
  }

  &__first {
    padding-top: 8px;
  }

  &__root {
    display: flex;
    position: relative;
  }

  &__icon {
    @include display-flex();
    flex-direction: column;
    cursor: move;
    padding: $padding-icon-wrapper-top 0 $padding-icon-wrapper $padding-icon-wrapper;
    flex: none;
    color: white;
    font-weight: bold;
    z-index: 1  ;


    .block-badge {
      position: absolute;
      left: 12px;
      color: red;
    }
    .block-icon {
      @include display-inline-flex();
      @include align-items(center);
      width: $width-icon-block;
      justify-content: space-between;
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      height: $height-icon;
      border-radius: 2px;
      padding: 0 6px;
      cursor: pointer;

      &__icon {
        @include display-flex();
        font-size : $icon-font-size-small;
        justify-content: center;
        align-items: center;
      }
      &__order {

      }
    }
    .block-number {
      margin-top: 1rem;
      padding: 0px 10px;
      font-family: Roboto;
      font-weight: 700;
      border-bottom: 2px solid #cae085;
      color: #0b0b0b;
      text-align: center;
    }
  }

  &__content {
    flex: 1;
    color: $color-grey-1;
    padding: 16px 24px 26px 16px;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }


  &__groupLine{
    position: absolute;
    top: 0;
    left: $padding-icon-wrapper + ($width-icon-block /2);
    bottom: 0;
    width: 1px;
    z-index: 1;
    color: #74cbe8;
    background: currentColor;
  }
  &__middle {
    &:after {
      position: absolute;
      content: '';
      width: $bullet-size;
      height: $bullet-size;
      top: $padding-icon-wrapper-top +($height-icon + $bullet-size)/2 - 8px;// -8px is a hack
      left: -3px;
      border: 1px solid currentColor;
      border-radius: 50%;
      background: currentColor;
    }
  }

  &__bottom {
    bottom: calc(100% - #{$padding-icon-wrapper-top+ $height-icon/2 + $bullet-size/2});
  }
  &__header{
    top: $height-icon + $padding-icon-wrapper-top -5px;
  }

  textarea {
    margin: 0px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: lightness($color-grey-1);

    }
  }
}

.help-text{
  margin-left: 8px;
  border-bottom: 1px lightgray solid;
  color: $color-grey-1;
  font-style: normal;
  font-size: $font-size-body;
}

.qst-block-badge {
  // width: 100%;
  border: 1px solid rgba(0,0,0,.07);
  background-color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-left: $padding-icon-wrapper;
  padding: 5px;
  margin-right: auto;

  //TODO 
  right: 20px;
  bottom: 5px;
  position: absolute;
  // fin TODO

  .badge-icon {
    box-shadow: inset -1px 0 rgba(0,0,0,.07);
    display: flex;
    align-items: center;
    // padding: 0 5px;
    padding: 0px 8px 0px 3px;
    font-size: 12px;
    line-height: 20px;
  }
  .badge-content {
    flex: 1;
    display: flex;
    padding-left: 5px;

    .content-elemnt {
      padding: 0 5px;
      max-width: 300px;
      overflow: hidden;
      max-height: 30px;

      &:not(:last-child) {
        box-shadow: inset -1px 0 rgba(0,0,0,.07);
      }
    }
  }
}

.editor-preview {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  > .preview {
    height: 100%;

    .submit-response {
      bottom: unset;
    }

    .preview--center::after {
      display: none;
    }
  }
}