$tab-button-height: 54px;
.ttp-tab-container {
  background: #FAFBFB;
  margin-top: 3rem;

  .ttp-tab-selector {
    position: relative;
    nav {
      position: absolute;
      top: -$tab-button-height/2;
      left: 0;
      right: 0;
      font-size: 15px;
      text-align: center;
      button {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        margin: 0 10px;
        cursor: pointer;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        width: 206px;
        height: $tab-button-height;
        font-family: $main-body-font;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #18A0FB;
        outline: none;
        transition: box-shadow .2s, transform .2s;
        &:hover {
          box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
          transform: scale(0.99);
        }
      }
    
      button.active {
        background: #18A0FB;
        border: 1px solid #EEF6FF;
        color: #fff;
      }
    }
  }
  &>div {
    padding: $spacing-s 0 $spacing-m;
  }
  &.light {
    background: #fff;
  }
}
