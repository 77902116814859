@import "../global-propierties";

@-webkit-keyframes blinker { from { opacity: 1.0; } to { opacity: 0.0; } }
@-moz-keyframes blinker { from { opacity: 1.0; } to { opacity: 0.0; } }
@keyframes blinker { from { opacity: 1.0; } to { opacity: 0.0; } }

.error-top {
  justify-content: center;
  align-items: center;
  display: flex;

  p {
    margin-bottom: 0;
    font-size: 156px;
    color: $color-alert;
    padding-right: 25px;
  }
  img {
    padding: 0;
    -webkit-animation-name: blinker;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    -webkit-animation-duration: 1s;
    -moz-animation-name: blinker;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    -moz-animation-duration: 1s;
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    animation-duration: 1s;
  }
}
