@import "global-propierties";

.stats-header {
  .title {
    margin-right: auto;
  }
}

.stats-btn{

  &:not(:last-child) {
    margin-right: 10px;
  }

  &--active {
    background-color: $main-survey-orange;
    opacity: 1;

    &:hover {
      background-color: $main-survey-orange;
      opacity: .8;
    }
  }

  &:focus{
    outline: none;
    background-color: $main-survey-orange;
  }

  &--margin{
    margin-right: 5px;
  }

  &--display{
    display: none;
  }
}

.cursor{
  cursor: pointer;
}
