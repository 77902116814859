.template {
    padding: 2rem 0;
    &-item {
        margin-bottom: 1.5rem;
        border-radius: 4px;
        &__title {
            text-align: center;
            font-weight: 600;
            color: black;

            &--full-centered {
                height: 100%;
                width: 100%;
                text-align: center;
                align-items: center;
                padding-left: 8px;
                padding-right: 8px;
            }
        }
        &__box {
            position: relative;
            height: 208px;
            border-radius: 4px;
            margin: .1rem;
            background-size: cover;
            background-repeat: round;
            cursor: pointer;
            &:hover {
                .template-item__overlay{
                    height: 100%;
                    opacity: 1;
                }
            }
            .warning-box {
                padding: .2rem .3rem;
                background-color: orange;
                color: white;
                border-radius: 1rem;
                font-size: .6rem;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        
        &__action{
            background-color: white;
            width: 80%;
            text-align: center;
            border-radius: 1px;
            font-size: 1rem;
            font-weight: bold;
            color: black;
            margin: .3rem;
            padding: .5rem 1rem;      
            text-transform: uppercase;
            opacity: .8;
            transition: .2s all ease-in-out;
            &:hover{
                opacity: 1;
            }
        }
    
        &__overlay{
            position: absolute;
            display: flex;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0%;
            transition: 0.2s all ease-in-out;
            background-color: #000;
            opacity: 0;
            overflow: scroll;
            flex-direction: column;
            z-index: 2;
            border-radius: 4px;
            span {
                font-size: 1.1rem;
                color: #fff;
            }
        }
    }
}