// Loop
$transitions: ();
@for $i from 1 through 20 {
  $transitions: append($transitions, #{$i * 0.1}s all ease-in-out);
};
$main-blue-alpha: ();
@for $j from 1 through 10 {
  $main-blue-alpha: append($main-blue-alpha, rgba(80, 129, 190, ($j * 0.1)));
};
$main-orange-alpha: ();
@for $j from 1 through 10 {
  $main-orange-alpha: append($main-orange-alpha, rgba(244, 150, 73, ($j * 0.1)));
};
// Colors
// main
$main-background-color: nth($main-blue-alpha, 1);
// $main-color: #8d8d8d;
$main-color: $fiord;
$main-blue: rgb(80, 129, 190);
$main-orange: rgb(244, 150, 73);
$main-light-gray: #f3f3f3;
$main-medium-gray: #9a9a9a;
$main-dark-gray: #505050;
$gray-dark: #505050;
$gray-88: #e0e0e0;
$main-header-font: Roboto, Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;;
$main-body-font: Roboto, 'Segoe UI', Helvetica, sans-serif;;
$header-controls-bg-color: rgb(55, 60, 68);
//$mask-bg-color: rgba(55, 60, 68, 0.8);
$mask-bg-color: rgba(112, 112,112, 0.8);
$bg-empty-avatar: linear-gradient(to bottom right, #DDD, #CCC, #C5C5C5, #BBB, #B5B5B5, #AAA);

// left-side
$left-side-color: #FFFFFF;
$pagesMenu-bg-color: $main-blue;
// article container
$article-bg-color: #FFFFFF;
//$article-username-color: rgba(255,255,255,.8);
//$article-title-color: #666666;
//$article-content-color: #888888;
$dragging-article-bg-color: rgb(238, 243, 249);

// right-side
$right-side-background-color: nth($main-blue-alpha, 3);
// tmpl
$tmpl-area-bg-color: #FFFFFF;
$tmpl-img-bg-color: nth($main-blue-alpha, 3);
$tmpl-img-border-color: nth($main-blue-alpha, 1);
$tmpl-txt-bg-color: nth($main-blue-alpha, 1);
$tmpl-txt-title-bg-color: nth($main-blue-alpha, 8);

$drop-target-is-active-shadow:0 3px 11px rgba(244, 150, 73, 1);
$drop-target-can-drop-shadow: 0 3px 11px $main-blue;

$dock-bg-color: rgba(55, 60, 68, 0.2);
// Box-shadow
$box-shadow: 1px 2px 20px 1px #d3d3d3;
$thin-box-shadow: 0 1px 1px 1px rgba(0,0,0,0.06);

$h-font-familly: "Montserrat";
$h-color: rgb(63, 63, 63);

//Component StatusCell

$campaign-sent-status-icon-color: #18c559;

// Component Profile
$profile-bg-color: $main-light-gray;
$profile-height: rem-calc(260px);
$profile-info-height: rem-calc(340px);
$profile-info-top: rem-calc(-$profile-info-height/2);
$profile-info-bg-color: $white;
$profile-info-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
$profile-avatar-height: rem-calc(128px);
$profile-avatar-width: $profile-avatar-height;
$profile-avatar-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
$profile-avatar-bg-color: $white;
$profile-avatar-border-color: $white;
$profile-username-font-family: $h-font-familly;
$profile-username-color: $main-dark-gray;
$profile-stats-color: $main-blue;
$profile-social-link-color: $main-medium-gray;
$profile-social-link-bg-color: $main-light-gray;
$profile-social-link-height: rem-calc(32px);
$profile-social-link-width: rem-calc(32px);
$profile-social-link-padding: rem-calc(8px);

// Component Pagination
$pagination-bg-color: $main-light-gray;
$pagination-height: rem-calc(50px);
$pagination-item-height: rem-calc(40px);
$pagination-item-color: $main-color;
$pagination-item-hover-color: $main-blue;
$pagination-item-active-color: $white;
$pagination-item-active-bg-color: $main-blue;

// Component Tag
$tag-font-family: $h-font-familly;
$tag-bg-color: #F0F0F0;
$tag-color: #18A0FB;
$tag-hover-bg-color: $main-blue;
$tag-hover-color: $white;
$tag-margin: 0 1rem 1rem 0;
$tag-padding: .5rem 1rem;

// Component Search
$search-input-color: $main-color;
$search-input-border-color: #e3e3e3;
$search-submit-color: $main-dark-gray;

// Component StatusList
$status-list-item-padding: rem-calc(4px);
$status-list-item-font-size: rem-calc(12px);
$info-font-size: rem-calc(17px);
$status-list-item-bg-color: #F0F0F0;
$status-list-item-color: rgb(141, 141, 141);
$status-list-item-hover-color: $white;
$status-list-item-hover-bg-color: $main-blue;
$status-list-item-height: rem-calc(36px);
$status-list-item-margin-top: rem-calc(8px);
$status-list-item-line-height: rem-calc(28px);

// Component Boxes
$boxes-height:250px;
$boxes-bg-color:  #FFFFFF;
$box-logo-height: 64px;
$box-padding: 20px;

// Component Category page
$text-bar-height : 130px;
$title-skeleton-bg-color: $main-light-gray;

//Footer
$footer-bg-color: #262626;
$footer-color: #e3e3e3;

$a-hover-color: #1468a0;
$light-border: 1px solid rgba(44, 43, 63, 0.1);

$shadow-like-border: 0 0 0 0.06rem rgba(0,0,0,.06), 0 0.13rem 0.2rem 0 rgba(0,0,0,.06);

//Buttons
$delete-button-color: #c0392b;

$h-font-family: "Montserrat";
$shadow-like-border: 0 0 0 0.06rem rgba(0,0,0,.06), 0 0.13rem 0.2rem 0 rgba(0,0,0,.06);

//Reveal
$reveal-bg: #F2F2F2;
$main-box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
$survey-color: #373c54;
$survey-blue: #2f47c2;
$icon-color: #69719d;
$image-shadow: 0 1px 15px 1px rgba(0,0,0,.24), 0 1px 6px rgba(0,0,0,.04);

$article-title-link-color: #3F3F3F;
