#viewer, #emailingViewer {
  width: 100%;
  .rmodal__body {
    padding: 0;
  }
  .rmodal__close {
    right: 1rem;
    top: -1rem;

    .back {
      cursor: pointer;
      position: relative;
      height: 1.2rem;
      width: 1.25rem;
      &::before, &::after {
        content: "";
        width: 2px;
        height: 1.3rem;
        background: #444;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border-radius: 30%;
      }
      &::before {
        top: 0;
        left: 50%;
      }
      &::after {
        height: 2px;
        width: 1.3rem;
        left: 0;
        top: 50%;
      }
    }
  }
  .empty-avatar{
    top: 0;
    margin: 0 1rem 0 0;
  }
  .social-media{
    background-color: $survey-color;
  }
}
#emailingViewer {
  .rmodal__article-header {
    position: fixed;
    height: 3rem;
    box-shadow: $shadow-like-border;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    align-items: center;
    padding: 0 7rem;
    input {
      margin: 0;
      font-family: $h-font-family;
      border: none;
      background-color: #F5F5F5;
      box-shadow: none;
      border-radius: 2rem;
      padding: .5rem 3rem .5rem 2rem;
      font-size: .8rem;
      font-weight: 500;
    }
    a {
      position: absolute;
      right: 7.3rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $icon-color;
      border-radius: 50%;
      font-size: 1rem;
      color: #fff;
      transform: rotate(45deg);
    }
  }
  .rmodal__close {
    z-index: 2;
    background: #fff;
    top: 0.5rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    .back {
      &::before {
        top: -5px;
        transform: rotate(55deg);
      }
      &::after {
        top: 90%;
        transform: rotate(35deg);
      }
    }
  }
}
#pdf-viewer {
  .ReactModal__Overlay {
    z-index: 11;
  }
}
