$search-border-radius: 30px;
$search-box-width: 357px;
$search-input-width: 300px;
$search-box-height: 32px;

.list-filter__bar {
    display: flex;
    justify-content: flex-end;
    margin: 1rem auto;
}
.search-box {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E8EAED;
    box-sizing: border-box;
    border-radius: $search-border-radius;
    height: $search-box-height;
    width: $search-box-width;
    justify-content: space-around;
    align-items: center;
    input {
        width: $search-input-width;
        border-radius: $search-border-radius;
        border: none;
        outline: none;
        margin: 0;
        color: #3C4E64;
        &::placeholder {
            color: #B7BDC3;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
        }
    }
    .icon-tt-close {
        cursor: pointer;
        color: #B7BDC3;
        margin-right: .5rem;
    }
    &__icon:focus {
        outline: none;
    }

    &__icon {
        color: #B7BDC3;
        cursor: pointer;
        font-size: 1.2rem;
        display: inherit;
        margin: auto .5rem;
    }
}

.ttp-page-size {
    background: #F1F2F4;
    height: $search-box-height;
    border-radius: $search-border-radius;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3C4E64;
    width: 79px;
    padding-top: 0;
    padding-bottom: 0;
    background-image: url("/img/icons/polygon.svg");
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding-right: 1.5rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    border: none;
    cursor: pointer;
    margin: 0;
}
